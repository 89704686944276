import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { Model } from 'helper/products/meteorology';
import ModelCheckbox from '../ModelCheckbox';

export default function ModelsAccordionItem({
  selectableModels,
  onNoneClicked,
  eventKey,
}: {
  selectableModels: Model[],
  onNoneClicked: () => void,
  eventKey: string,
}) {
  return (
    <Accordion.Item eventKey={eventKey} className="control-panel-item">
      <Accordion.Header>Modelo</Accordion.Header>
      {selectableModels.length === 0 && (
        <Accordion.Body className="w-100">
          Selecione uma variável
        </Accordion.Body>
      )}
      {selectableModels.length > 0 && (
        <Accordion.Body className="d-grid control-panel-columns">
          {selectableModels.map((m) => (
            <ModelCheckbox key={m.value} value={m.value} label={m.label} />
          ))}
          <Button variant="link" className="btn-sm text-start" onClick={onNoneClicked}>Nenhum</Button>
        </Accordion.Body>
      )}
    </Accordion.Item>
  );
}
