import { useMemo } from 'react';
import { ResponseError } from 'services/api/base';
import { useGetSeasonalForecastOptionsQuery } from 'services/api/Meteorology/Weather/SeasonalForecast';
import { useHandleRequestErrors } from 'utils/hooks';

export default function useSeasonalComparatorOptions() {
  const { data, error } = useGetSeasonalForecastOptionsQuery();

  useHandleRequestErrors(error as ResponseError);

  const options = useMemo(() => (data?.map((variable) => {
    const variableAreas = variable.models.flatMap((model) => model.areas.map((area) => ({
      id: area.id,
      label: area.label,
    })));
    const uniqueAreas = variableAreas.filter((area, index) => (
      variableAreas.findIndex((a) => a.id === area.id) === index
    ));
    const areas = uniqueAreas.map((area) => ({
      id: area.id,
      label: area.label,
      models: variable.models.filter((model) => model.areas.some((a) => a.id === area.id))
        .map((model) => ({
          id: model.id,
          label: model.label,
        })),
    }));
    return {
      id: variable.id,
      label: variable.label,
      areas,
    };
  }) ?? []), [data]);

  return { options };
}
