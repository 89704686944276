import React from 'react';
import {
  Accordion, Row, Button,
} from 'react-bootstrap';
import Preferences from './Preferences';
import Variable from './Variable';
import Area from './Area';
import Model from './Model';
import './index.scss';

export default function ComparatorControlPanel() {
  return (
    <div>
      <Accordion defaultActiveKey="0">
        <Preferences />
        <Variable />
        <Area />
      </Accordion>
      <Model modelIndex={0} />
      <Model modelIndex={1} />
      <Row className="pt-5">
        <Button
          variant="primary"
          onClick={() => {}}
        >
          Salvar nova preferência
        </Button>
      </Row>
      <Row>
        <Button variant="link" className="clear-filters bordered-clear-filters" onClick={() => {}}>
          Limpar filtros
        </Button>
      </Row>
    </div>
  );
}
