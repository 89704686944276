import { useListPreferencesQuery } from 'services/api/Meteorology/Weather/SeasonalForecast';
import { ResponseError } from 'services/api/base';
import { useHandleRequestErrors } from 'utils/hooks';

export default function usePreferences() {
  const { data, error, isFetching } = useListPreferencesQuery();

  useHandleRequestErrors(error as ResponseError | undefined);

  return {
    preferences: data,
    isFetching,
  };
}
