import { NavigateFunction } from 'react-router';

import baseApi from 'services/api/base';
import { reset } from 'redux/reducers/authReducer';
import { store } from 'redux/store';

interface Alert {
  title: string;
  message: string;
  variant: string;
}

const logout = (navigate: NavigateFunction, alert: Alert, path = '/') => {
  const encodedPath = path !== '/' ? encodeURI(path) : null;
  const signInPath = encodedPath ? `/signin?path=${path}` : '/signin';

  store.dispatch(reset());
  store.dispatch(baseApi.util.resetApiState());

  navigate(signInPath, { state: { alerts: [alert] } });
};

export default logout;
