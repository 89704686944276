import React from 'react';
import { Accordion, Stack } from 'react-bootstrap';

import DatePicker from 'components/DatePicker';
import useClassicComparatorDates from 'utils/hooks/meteorology/classicComparator/useClassicComparatorDates';

export default function DateSelectors() {
  const {
    possibleIntervals,
    initialDate,
    setInitialDate,
    finalDate,
    setFinalDate,
  } = useClassicComparatorDates();

  return (
    <Accordion.Item eventKey="dates" className="control-panel-item">
      <Accordion.Header>Período de Análise</Accordion.Header>
      <Accordion.Body>
        <Stack>
          <DatePicker
            className="period-selector"
            selected={initialDate}
            onChange={(d) => setInitialDate(d)}
            placeholderText="Data Inicial"
            includeDates={possibleIntervals}
          />
          <DatePicker
            className="period-selector"
            selected={finalDate}
            onChange={(d) => setFinalDate(d)}
            placeholderText="Data Final"
            includeDates={possibleIntervals}
          />
        </Stack>
      </Accordion.Body>
    </Accordion.Item>
  );
}
