import { useCallback } from 'react';
import { ResponseError } from 'services/api/base';
import { useDeletePreferenceMutation } from 'services/api/Meteorology/Weather/SeasonalForecast';
import { useHandleRequestErrors } from 'utils/hooks';

export default function useDeletePreference() {
  const [trigger, { isLoading, error }] = useDeletePreferenceMutation();
  const preferenceDeleteTrigger = useCallback((id: number) => () => {
    trigger({ id });
  }, [trigger]);

  useHandleRequestErrors(error as ResponseError | undefined);

  return {
    preferenceDeleteTrigger,
    isLoading,
  };
}
