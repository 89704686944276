import { useCallback, useMemo } from 'react';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  reset,
  selectMWComparatorControlPanelVariable,
  setVariable as setVariableRedux,
} from 'redux/reducers/meteorologyWeatherComparatorControlPanelReducer';
import useSeasonalComparatorOptions from './useSeasonalComparatorOptions';

export default function useVariable() {
  const variable = useSelector(selectMWComparatorControlPanelVariable);
  const { options } = useSeasonalComparatorOptions();

  const variables = useMemo(() => options.map((option) => ({
    id: option.id,
    label: option.label,
  })), [options]);

  const setVariable = useCallback((value: string) => {
    store.dispatch(reset());
    store.dispatch(setVariableRedux(value));
  }, []);

  return {
    variables,
    variable,
    setVariable,
  };
}
