import React, { useEffect } from 'react';
import Instructions from 'components/Meteorology/Fields/ClassicComparator/Instructions';
import { setCurrentTab } from 'redux/reducers/meteorologyFieldsLayoutReducer';
import { store } from 'redux/store';
import useImages from 'hooks/Meteorology/Fields/ClassicComparator/useImages';
import buildImageDisplayData from 'helper/Meteorology/ClassicComparator/buildImageDisplayData';
import Toolbar from 'components/Meteorology/Fields/ClassicComparator/Toolbar';
import Display from 'components/Meteorology/Fields/Display';
import AlertsCollection from 'components/AlertsCollection';
import CentralizedSpinner from 'components/CentralizedSpinner';
import useHandleImageRowSizeChange from 'utils/hooks/meteorology/useHandleImageRowSizeChange';
import useImagesDownload from 'utils/hooks/meteorology/classicComparator/useImagesDownload';

export default function ClassicComparator() {
  const { images, alert, isFetching } = useImages();
  const {
    imageSets,
    columnLabels,
    rowLabels,
  } = buildImageDisplayData(images);
  const showInstructions = images === undefined && alert === undefined && !isFetching;

  useHandleImageRowSizeChange(columnLabels);

  const { downloadDisabled, onDownloadClick } = useImagesDownload(images);

  useEffect(() => {
    store.dispatch(setCurrentTab('classiccomparator'));
  }, []);

  if (isFetching) {
    return (
      <div className="mt-5">
        <CentralizedSpinner />
      </div>
    );
  }

  if (showInstructions) {
    return <Instructions />;
  }

  return (
    <>
      {alert && (
        <div className="mt-5">
          <AlertsCollection alerts={[alert]} />
        </div>
      )}
      {!alert && (
        <>
          <div className="mt-5 mx-5 d-flex justify-content-end">
            <Toolbar
              onDownloadClick={onDownloadClick}
              downloadDisabled={downloadDisabled}
            />
          </div>
          <Display
            imageSets={imageSets}
            columnLabels={columnLabels}
            rowLabels={rowLabels}
            fetchingImages={isFetching}
          />
        </>
      )}
    </>
  );
}
