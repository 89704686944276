import React from 'react';
import ReactSlider from 'react-slider';
import { Button } from 'react-bootstrap';
import {
  faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useSliderProps from 'hooks/Meteorology/Weather/SeasonalForecast/useSliderProps';
import useSelectionPeriods from 'hooks/Meteorology/Weather/SeasonalForecast/useSelectionPeriods';

import './index.scss';

export default function PeriodSlider() {
  const {
    periodOffset,
    setPeriodOffset,
    formattedPeriod,
    isDisabled,
  } = useSelectionPeriods();

  const {
    sliderMax,
    sliderMin,
    sliderOnChange,
  } = useSliderProps();

  return (
    <div className={`seasonal-forecast-period-control ${isDisabled ? 'disabled' : ''}`}>
      <Button variant="link">
        <FontAwesomeIcon
          className="seasonal-forecast-slider-control-button"
          icon={faAngleDoubleLeft}
          onClick={() => setPeriodOffset(sliderMin)}
        />
      </Button>
      <Button variant="link">
        <FontAwesomeIcon
          className="seasonal-forecast-slider-control-button"
          icon={faAngleLeft}
          onClick={() => setPeriodOffset(Math.max(periodOffset - 1, sliderMin))}
        />
      </Button>
      <ReactSlider
        max={sliderMax}
        min={sliderMin}
        value={periodOffset}
        onChange={sliderOnChange}
        className="horizontal-slider seasonal-forecast-slider"
        thumbClassName="seasonal-forecast-thumb"
        trackClassName="seasonal-forecast-track"
        renderThumb={(props) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
          <div {...props}>
            <div className="thumb-value">{formattedPeriod}</div>
          </div>
        )}
        pearling
        minDistance={1}
      />
      <Button variant="link">
        <FontAwesomeIcon
          className="seasonal-forecast-slider-control-button"
          icon={faAngleRight}
          onClick={() => setPeriodOffset(Math.min(periodOffset + 1, sliderMax))}
        />
      </Button>
      <Button variant="link">
        <FontAwesomeIcon
          className="seasonal-forecast-slider-control-button"
          icon={faAngleDoubleRight}
          onClick={() => setPeriodOffset(sliderMax)}
        />
      </Button>
    </div>
  );
}
