import React from 'react';
import {
  Accordion, Row, Button,
} from 'react-bootstrap';

import { store } from 'redux/store';
import { setRequestTriggered } from 'redux/reducers/meteorologyFieldsClassicComparatorControlPanelReducer';
import useGetImagesParams from 'hooks/Meteorology/Fields/ClassicComparator/useGetImagesParams';
import useClassicComparatorUtility from 'utils/hooks/meteorology/classicComparator/useClassicComparatorUtility';
import VariableSelector from './VariableSelector';
import DateSelectors from './DateSelectors';
import BaseModel from './BaseModel';
import ConfrontingModel from './ConfrontingModel';

export default function ClassicComparator() {
  const { resetPreferences } = useClassicComparatorUtility();
  const params = useGetImagesParams();
  const triggerRequest = () => store.dispatch(setRequestTriggered(true));

  return (
    <>
      <Accordion defaultActiveKey="variable">
        <VariableSelector />
        <Accordion.Item eventKey="models" className="control-panel-item">
          <Accordion.Header>Modelos</Accordion.Header>
          <Accordion.Body>
            <ConfrontingModel />
            <BaseModel />
          </Accordion.Body>
        </Accordion.Item>
        <DateSelectors />
      </Accordion>
      <Row className="pt-5">
        <Button
          variant="primary"
          disabled={!params}
          onClick={triggerRequest}
        >
          Realizar consulta
        </Button>
      </Row>
      <Row>
        <Button variant="link" className="clear-filters" onClick={resetPreferences}>
          Limpar filtros
        </Button>
      </Row>
    </>
  );
}
