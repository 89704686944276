import React, { Children, PropsWithChildren, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Stack } from 'react-bootstrap';
import shortenedTabPath from 'helper/shortenedTabPath';

import TabbedPageTab from './Tab';

import './index.scss';

export default function TabbedPage({
  children,
  setControlPanelVisibility,
}: PropsWithChildren<{
  setControlPanelVisibility?: (value: React.SetStateAction<boolean>) => void;
}>) {
  const location = useLocation();

  const tabs = Children.toArray(children) as React.ReactElement[];

  const path = shortenedTabPath(location.pathname);
  const activeTab = tabs.find((tab) => path === tab.props.path);
  const activeTabPath = activeTab?.props?.path;
  const showControlPanel = activeTab?.props?.showControlPanel;

  useEffect(() => {
    if (setControlPanelVisibility) setControlPanelVisibility(showControlPanel);
  }, [showControlPanel, setControlPanelVisibility]);

  return (
    <div className="tabbed-page">
      <Stack direction="horizontal" gap={1} className="nav nav-tabs">
        {tabs.map((tab) => (
          <Link
            key={tab.props.path}
            to={tab.props.path}
            className={`nav-link ${tab.props.path === activeTabPath ? 'active' : ''}`}
          >
            {tab.props.title}
          </Link>
        ))}
      </Stack>
      <div className="tabbed-page-content">
        <Outlet />
      </div>
    </div>
  );
}

TabbedPage.Tab = TabbedPageTab;
TabbedPage.defaultProps = {
  setControlPanelVisibility: undefined,
};
