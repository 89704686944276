import React, { useMemo } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import useArea from 'hooks/Meteorology/Weather/Comparator/useArea';
import buildAccordionClickFunction from 'helper/buildAccordionClickFunction';

export default function Area() {
  const { areas, area, setArea } = useArea();
  const closeAccordion = useMemo(() => buildAccordionClickFunction('area-accordion'), []);

  return (
    <Accordion.Item eventKey="area" className="control-panel-item" id="area-accordion">
      <Accordion.Header>Área de plotagem</Accordion.Header>
      <Accordion.Body>
        {areas.length === 0 && (
          <div>
            Selecione uma variável.
          </div>
        )}
        {areas.map((v) => (
          <Card.Body key={v.id}>
            <Button
              key={v.id}
              variant="link"
              onClick={() => {
                setArea(v.id);
                closeAccordion();
              }}
              className={`w-100 btn-control-panel ${area === v.id ? 'btn-control-panel-active' : ''}`}
            >
              {v.label}
            </Button>
          </Card.Body>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
