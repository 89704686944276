import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { PreferenceList } from 'services/api/Meteorology';
import dateDiffToDate from 'helper/dateDiffToDate';
import useNumericForecastControlPanel from 'utils/hooks/meteorology/useNumericForecastControlPanel';

export default function Preference({ preference, onDelete, deleteLoading }: {
  preference: PreferenceList,
  onDelete: ({ id }: { id: number }) => void,
  deleteLoading: boolean,
}) {
  const {
    setPeriod,
    setModelArray,
    setVariable,
    setRegion,
    setForecastUnixDate,
    setAnomaly,
    resetPreferences,
  } = useNumericForecastControlPanel();
  const onDeleteClick = () => {
    onDelete({ id: preference.id });
  };
  const deleteButtonIcon = deleteLoading ? (
    <Spinner size="sm" />
  ) : (
    <FontAwesomeIcon icon={faTrash} size="sm" />
  );

  const loadPreference = () => {
    const today = new Date();
    const date = dateDiffToDate(preference.json.data_prev, [today]) || today;

    resetPreferences();
    setPeriod(preference.json.periodo_interesse);
    setVariable(preference.json.variavel_meteorologica);
    setModelArray(preference.json.modelos);
    setRegion(preference.json.area_plotagem);
    setForecastUnixDate(date);
    setAnomaly(preference.json.anom);
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <Button
        variant="link"
        onClick={loadPreference}
        className="btn-control-panel flex-grow-1"
      >
        { preference.name }
      </Button>
      <Button
        className="btn-preference"
        onClick={onDeleteClick}
        disabled={deleteLoading}
      >
        {deleteButtonIcon}
      </Button>
    </div>
  );
}
