import React from 'react';
import CentralizedSpinner from 'components/CentralizedSpinner';
import EnlargeableImage from 'components/Meteorology/Fields/SynopticCharts/SynopticChart/Map/EnlargeableImage';
import useImages from 'hooks/Meteorology/Weather/SeasonalForecast/useImages';
import './index.scss';

export default function MeteorologyWeatherImageDisplay() {
  const { image, loading } = useImages();

  return (
    <div className="seasonal-forecast-image">
      {loading && <CentralizedSpinner />}
      {!loading && (
        <EnlargeableImage
          src={`data:image/png;base64,${image}`}
          className={`${image ? '' : 'no-image'}`}
        />
      )}
    </div>
  );
}
