import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import useVariables from 'hooks/Meteorology/Weather/SeasonalForecast/useVariables';

export default function Variables() {
  const {
    variables,
    variable,
    setVariable,
  } = useVariables();

  return (
    <Accordion.Item eventKey="variables" className="control-panel-item">
      <Accordion.Header>Variável</Accordion.Header>
      <Accordion.Body>
        {variables.map((v) => (
          <Card.Body key={v.id}>
            <Button
              key={v.id}
              variant="link"
              onClick={() => setVariable(v.id)}
              className={`w-100 btn-control-panel ${variable === v.id ? 'btn-control-panel-active' : ''}`}
            >
              {v.label}
            </Button>
          </Card.Body>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
