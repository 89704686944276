import { useCallback } from 'react';
import { store } from 'redux/store';
import { reset } from 'redux/reducers/meteorologyWeatherSeasonalForecastControlPanelReducer';

export default function useControlPanelUtility() {
  const clearFilters = useCallback(() => {
    store.dispatch(reset());
  }, []);

  return {
    clearFilters,
  };
}
