import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DraftArray } from 'types/draftArray';
import { SeasonalForecastModel } from 'types/Meteorology/Weather/SeasonalForecast/getSeasonalForecastOptions';

interface MeteorologyWeatherComparatorControlPanelState {
  area: string | undefined;
  variable: string | undefined;
  model: DraftArray<SeasonalForecastModel>;
  modelPeriods: { startDate: string | null; endDate: string | null }[];
}

const initialState: MeteorologyWeatherComparatorControlPanelState = {
  area: undefined,
  variable: undefined,
  model: [undefined, undefined],
  modelPeriods: [
    { startDate: '', endDate: '' },
    { startDate: '', endDate: '' },
  ],
};

export const meteorologyWeatherComparatorControlPanelSlice = createSlice({
  name: 'meteorologyWeatherComparatorControlPanel',
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, initialState);
    },
    setArea: (state, action: PayloadAction<string | undefined>) => {
      state.area = action.payload;
    },
    setVariable: (state, action: PayloadAction<string | undefined>) => {
      state.variable = action.payload;
    },
    setModel: (state, action: PayloadAction<(SeasonalForecastModel | undefined)[]>) => {
      state.model = action.payload;
    },
    setModelPeriods: (state, action: PayloadAction<{ startDate: string; endDate: string }[]>) => {
      state.modelPeriods = action.payload;
    },
  },
});

export const selectMWComparatorControlPanelArea = (state: any) => (
  state.meteorologyWeatherComparatorControlPanel.area
);

export const selectMWComparatorControlPanelVariable = (state: any) => (
  state.meteorologyWeatherComparatorControlPanel.variable
);

export const selectMWComparatorControlPanelModel = (state: any) => (
  state.meteorologyWeatherComparatorControlPanel.model
);

export const selectMWComparatorControlPanelModelPeriods = (state: any) => (
  state.meteorologyWeatherComparatorControlPanel.modelPeriods
);

export const {
  reset,
  setArea,
  setVariable,
  setModel,
  setModelPeriods,
} = meteorologyWeatherComparatorControlPanelSlice.actions;

export default meteorologyWeatherComparatorControlPanelSlice.reducer;
