import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { store } from 'redux/store';
import useSeasonalForecastOptions from 'hooks/Meteorology/Weather/SeasonalForecast/useSeasonalForecastOptions';
import {
  selectMWSeasonalForecastControlPanelModel,
  setModel as setModelRedux,
  selectMWSeasonalForecastControlPanelVariable,
} from 'redux/reducers/meteorologyWeatherSeasonalForecastControlPanelReducer';
import { SeasonalForecastModel } from 'types/Meteorology/Weather/SeasonalForecast/getSeasonalForecastOptions';

export default function useModel() {
  const selectedVariable = useSelector(selectMWSeasonalForecastControlPanelVariable);
  const { options } = useSeasonalForecastOptions();
  const models : SeasonalForecastModel[] = options
    .filter((v) => (v.id === selectedVariable))
    .flatMap((v) => (v.models));

  const setModel = (model: string) => { store.dispatch(setModelRedux(model)); };
  const model = useSelector(selectMWSeasonalForecastControlPanelModel);

  useEffect(() => {
    const modelIds = models.map((m) => m.id);
    const modelIsMissing = !modelIds.includes(model);
    if (modelIsMissing) store.dispatch(setModelRedux(undefined));
  }, [model, models]);

  return {
    models,
    setModel,
    model,
  };
}
