import { useCallback } from 'react';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  selectMWComparatorControlPanelArea,
  selectMWComparatorControlPanelModel,
  selectMWComparatorControlPanelVariable,
  setModel as setModelRedux,
} from 'redux/reducers/meteorologyWeatherComparatorControlPanelReducer';
import useSeasonalComparatorOptions from './useSeasonalComparatorOptions';

export default function useModel() {
  const { options } = useSeasonalComparatorOptions();
  const currentModels = useSelector(selectMWComparatorControlPanelModel);
  const selectedVariable = useSelector(selectMWComparatorControlPanelVariable);
  const selectedArea = useSelector(selectMWComparatorControlPanelArea);

  const areas = options.find((v) => (v.id === selectedVariable))?.areas;
  const models = areas?.find((a) => (a.id === selectedArea))?.models || [];

  const getModels = useCallback((index: number) => currentModels[index], [currentModels]);
  const setModels = useCallback((value: string, index: number) => {
    const newModels = [...currentModels];
    newModels[index] = value;
    store.dispatch(setModelRedux(newModels));
  }, [currentModels]);

  return {
    models,
    getModels,
    setModels,
  };
}
