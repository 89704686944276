import React from 'react';
import { Accordion } from 'react-bootstrap';
import { GenericPreference } from 'types/genericPreference';
import Preference from './Preference';

export default function PreferencesAccordion({
  preferenceList,
  selectTrigger,
  deleteTrigger,
  deleteLoading,
}: {
  preferenceList: GenericPreference[] | undefined,
  selectTrigger: (preference: GenericPreference) => () => void,
  deleteTrigger: (id: number) => () => void,
  deleteLoading: boolean,
}) {
  return (
    <Accordion.Item eventKey="1" className="control-panel-item">
      <Accordion.Header>Preferências Salvas</Accordion.Header>
      <Accordion.Body>
        { preferenceList?.map((preference) => (
          <Preference
            key={preference.id}
            preference={preference}
            onSelect={selectTrigger(preference)}
            onDelete={deleteTrigger(preference.id)}
            deleteLoading={deleteLoading}
          />
        )) }
      </Accordion.Body>
    </Accordion.Item>
  );
}
