import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MeteorologyWeatherSeasonalForecastControlPanelState {
  date: string | undefined;
  variable: string | undefined;
  area: string | undefined;
  period: string | undefined;
  periodOffset: number;
  model: string | undefined;
  layers: boolean;
}

const initialState: MeteorologyWeatherSeasonalForecastControlPanelState = {
  date: undefined,
  variable: undefined,
  area: undefined,
  period: undefined,
  periodOffset: 0,
  model: undefined,
  layers: false,
};

export const meteorologyWeatherSeasonalForecastControlPanelSlice = createSlice({
  name: 'meteorologyWeatherSeasonalForecastControlPanel',
  initialState,
  reducers: {
    setDate: (state, action: PayloadAction<string | undefined>) => {
      state.date = action.payload;
    },
    setVariable: (state, action: PayloadAction<string | undefined>) => {
      state.variable = action.payload;
    },
    setArea: (state, action: PayloadAction<string | undefined>) => {
      state.area = action.payload;
    },
    reset: (state) => {
      Object.assign(state, initialState);
    },
    setPeriod: (state, action: PayloadAction<string>) => {
      state.period = action.payload;
    },
    setPeriodOffset: (state, action: PayloadAction<number>) => {
      state.periodOffset = action.payload;
    },
    setModel: (state, action: PayloadAction<string | undefined>) => {
      state.model = action.payload;
    },
    setLayers: (state, action: PayloadAction<boolean>) => {
      state.layers = action.payload;
    },
  },
});

export const selectMWSeasonalForecastControlPanelArea = (state: any) => (
  state.meteorologyWeatherSeasonalForecastControlPanel.area
);

export const selectMWSeasonalForecastControlPanelDate = (state: any) => (
  state.meteorologyWeatherSeasonalForecastControlPanel.date
);

export const selectMWSeasonalForecastControlPanelVariable = (state: any) => (
  state.meteorologyWeatherSeasonalForecastControlPanel.variable
);

export const selectMWSeasonalForecastControlPanelPeriod = (state: any) => (
  state.meteorologyWeatherSeasonalForecastControlPanel.period
);

export const selectMWSeasonalForecastControlPanelPeriodOffset = (state: any) => (
  state.meteorologyWeatherSeasonalForecastControlPanel.periodOffset
);

export const selectMWSeasonalForecastControlPanelModel = (state: any) => (
  state.meteorologyWeatherSeasonalForecastControlPanel.model
);

export const selectMWSeasonalForecastControlPanelLayers = (state: any) => (
  state.meteorologyWeatherSeasonalForecastControlPanel.layers
);

export const {
  setArea,
  setDate,
  setVariable,
  setModel,
  reset,
  setPeriod,
  setPeriodOffset,
  setLayers,
} = meteorologyWeatherSeasonalForecastControlPanelSlice.actions;

export default meteorologyWeatherSeasonalForecastControlPanelSlice.reducer;
