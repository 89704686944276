import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './index.scss';

const quillModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image'],
    ['clean'],
  ],
};

function RichTextEditor({ id, value, onChange }: {
  id?: string,
  value: string,
  onChange: (value: string) => void,
}) {
  return (
    <ReactQuill
      id={id}
      className="react-quill"
      theme="snow"
      modules={quillModules}
      value={value}
      onChange={onChange}
    />
  );
}

RichTextEditor.defaultProps = { id: '' };

export default RichTextEditor;
