import React from 'react';
import { Modal } from 'react-bootstrap';
import SavePreferenceForm from './SavePreferenceForm';

import './index.scss';

export default function FormModal({
  show,
  valid,
  isLoading,
  onHide,
  onNameChange,
  handleSubmit,
}: {
  show: boolean;
  valid: boolean;
  isLoading: boolean;
  onHide: () => void;
  onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="default-modal modal-dialog-scrollable seasonal-forecast-dialog"
    >
      <Modal.Header closeButton className="confirmation-dialog-modal-header" />
      <Modal.Body className="p-4">
        <SavePreferenceForm
          valid={valid}
          isLoading={isLoading}
          onNameChange={onNameChange}
          handleSubmit={handleSubmit}
        />
      </Modal.Body>
    </Modal>
  );
}
