import React from 'react';

import { Card } from 'react-bootstrap';
import '../../NumericForecast/Instructions/index.scss';
import '../../Comparator/Instructions/index.scss';

export default function Instructions() {
  return (
    <Card className="shadow-sm forecast-instruction-card m-5">
      <Card.Header>
        NOVA COMPARAÇÃO
      </Card.Header>
      <Card.Body>
        <p className="comparison-text">
          Selecione as variáveis do modelo base e do modelo confrontante no painel
          de controle ao lado e sua comparação aparecerá na tela.
        </p>
      </Card.Body>
    </Card>
  );
}
