import { useMemo } from 'react';

import { ResponseError } from 'services/api/base';
import { useGetAvailableComparisonDatesQuery } from 'services/api/Meteorology';
import useHandleRequestErrors from 'utils/hooks/useHandleRequestErrors';
import sortDates from '../../../sortDates';

export default function useAvailableDates(model: string) {
  const { data, error } = useGetAvailableComparisonDatesQuery({ model });
  const dates = useMemo(() => sortDates(data), [data]);

  useHandleRequestErrors(error as ResponseError | undefined);

  return dates;
}
