import { ResponseError } from 'services/api/base';
import { useGetSeasonalForecastOptionsQuery } from 'services/api/Meteorology/Weather/SeasonalForecast';
import { useHandleRequestErrors } from 'utils/hooks';

export default function useSeasonalForecastOptions() {
  const { data, error } = useGetSeasonalForecastOptionsQuery();

  useHandleRequestErrors(error as ResponseError);

  return {
    options: data || [],
  };
}
