import React from 'react';
import { Accordion, Form } from 'react-bootstrap';
import useModel from 'hooks/Meteorology/Weather/SeasonalForecast/useModel';

export default function Models() {
  const { models, model, setModel } = useModel();

  return (
    <Accordion.Item eventKey="models" className="control-panel-item">
      <Accordion.Header>
        Modelo
      </Accordion.Header>
      {models.length === 0 && (
        <Accordion.Body>
          Selecione uma variável.
        </Accordion.Body>
      )}
      {models.length !== 0 && (
        <Accordion.Body className="d-grid control-panel-columns column-gap-3">
          {models.map((m) => (
            <Form.Check
              key={m.id}
              id={`model-checkbox-${m.id}`}
              type="radio"
              className="text-uppercase"
              label={m.label}
              onChange={() => { setModel(m.id); }}
              checked={m.id === model}
            />
          ))}
        </Accordion.Body>
      )}
    </Accordion.Item>
  );
}
