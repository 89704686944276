import React, { useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { Link, Outlet, useLocation } from 'react-router-dom';
import mapAnnotations from 'helper/Meteorology/Weather/Overview/AnomalyPanel/mapAnnotations';
import { store } from 'redux/store';
import { setCurrentTab } from 'redux/reducers/weatherLayoutReducer';
import './index.scss';

export default function Oscillations() {
  useEffect(() => {
    store.dispatch(setCurrentTab('oscillations'));
  }, []);
  const location = useLocation();

  return (
    <div>
      <Nav
        activeKey="./"
      >
        <Nav.Item className={`m-3 ${location.pathname === '/meteorology/weather/oscillations/' ? 'active-item' : ''}`}>
          <Link to="./">Visão Geral</Link>
        </Nav.Item>
        {
          mapAnnotations.map((entry) => (
            <Nav.Item className={`m-3 ${location.pathname === `/meteorology/weather/oscillations/${entry.url}` ? 'active-item' : ''}`}>
              <Link to={entry.url}>{entry.acronym}</Link>
            </Nav.Item>
          ))
        }
      </Nav>
      <hr className="m-1" />
      <div>
        <Outlet />
      </div>
    </div>
  );
}
