import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { GenericPreference } from 'types/genericPreference';

export default function Preference({
  preference,
  onDelete,
  onSelect,
  deleteLoading,
}: {
  preference: GenericPreference,
  onDelete: () => void,
  onSelect: () => void,
  deleteLoading: boolean,
}) {
  const deleteButtonIcon = deleteLoading ? (
    <Spinner size="sm" />
  ) : (
    <FontAwesomeIcon icon={faTrash} size="sm" />
  );

  return (
    <div className="d-flex flex-row align-items-center">
      <Button
        variant="link"
        onClick={onSelect}
        className="btn-control-panel flex-grow-1"
      >
        { preference.name }
      </Button>
      <Button
        className="btn-preference"
        onClick={onDelete}
        disabled={deleteLoading}
      >
        {deleteButtonIcon}
      </Button>
    </div>
  );
}
