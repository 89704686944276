import CentralizedSpinner from 'components/CentralizedSpinner';
import useOverview from 'hooks/Meteorology/Weather/Overview/useOverview';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import { selectUserIsAdmin } from 'redux/reducers/authReducer';
import LatestReportLink from 'components/Meteorology/Weather/Overview/LatestReportLink';
import usePeriodSelection from 'hooks/Meteorology/Weather/Overview/usePeriodSelection';
import AnomalyPanel from 'components/Meteorology/Weather/Overview/AnomalyPanel';
import FormModal from 'components/FormModal';
import SummaryForm from 'components/Meteorology/Weather/Overview/SummaryForm';
import EditButton from 'components/Shared/EditButton';

export default function Overview() {
  const [editing, setEditing] = useState(false);
  const [overviewSummary, setOverviewSummary] = useState('');
  const { period, periodOptions, handlePeriodChange } = usePeriodSelection();
  const { overview, isFetching, error } = useOverview(period);
  const userIsAdmin = useSelector(selectUserIsAdmin);

  useEffect(() => {
    setOverviewSummary(overview?.summary || '');
  }, [overview]);

  if (isFetching) {
    return <CentralizedSpinner />;
  }

  return (
    <>
      <Row className="flex-fill p-5 h-100">
        <Col md={4}>
          <Card className="shadow-sm forecast-instruction-card h-100">
            <Card.Header className="d-flex">
              <div className="flex-fill">
                RESUMO DAS CONDIÇÕES CLIMÁTICAS ATUAIS
              </div>
              {userIsAdmin && (
                <div className="h-100 d-flex align-items-center">
                  <EditButton id="edit-summary" onClick={() => setEditing(true)} />
                </div>
              )}
            </Card.Header>
            <Card.Body>
              <div className="weather-description">
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: overviewSummary }}
                  className="w-100 ql-editor"
                />
              </div>
            </Card.Body>
            <LatestReportLink />
          </Card>
        </Col>
        <Col md={8} className="mt-3 mt-md-0">
          <Card className="shadow-sm forecast-instruction-card h-100">
            <Card.Header>
              PAINEL DE OSCILAÇÕES CLIMÁTICAS E HISTÓRICO RECENTE DE ANOMALIA DA TSM
            </Card.Header>
            <Card.Body>
              <AnomalyPanel
                isFetching={isFetching}
                image={overview?.imageBase64 || ''}
                error={error}
                period={period}
                handlePeriodChange={handlePeriodChange}
                periodOptions={periodOptions}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <FormModal title="Editar resumo" show={editing} onHide={() => setEditing(false)}>
        <SummaryForm
          overview={overviewSummary}
          setOverview={setOverviewSummary}
        />
      </FormModal>
    </>
  );
}
