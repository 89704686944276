import React, { useMemo } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import useVariable from 'hooks/Meteorology/Weather/Comparator/useVariable';
import buildAccordionClickFunction from 'helper/buildAccordionClickFunction';

export default function Area() {
  const { variable, variables, setVariable } = useVariable();
  const closeAccordion = useMemo(() => buildAccordionClickFunction('variable-accordion'), []);

  return (
    <Accordion.Item eventKey="variable" className="control-panel-item" id="variable-accordion">
      <Accordion.Header>Variável</Accordion.Header>
      <Accordion.Body>
        {variables.map((v) => (
          <Card.Body key={v.id}>
            <Button
              key={v.id}
              variant="link"
              onClick={() => {
                setVariable(v.id);
                closeAccordion();
              }}
              className={`w-100 btn-control-panel ${variable === v.id ? 'btn-control-panel-active' : ''}`}
            >
              {v.label}
            </Button>
          </Card.Body>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
