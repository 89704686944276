import { FormEvent, useState } from 'react';

import { ResponseError } from 'services/api/base';
import { useSavePreferencesMutation } from 'services/api/Meteorology/Weather/SeasonalForecast';
import usePreferences from './usePreferences';
import useGetImagesParams from './useGetImagesParams';

export default function useSavePreference() {
  const [savePreferenceTrigger, { isLoading }] = useSavePreferencesMutation();
  const params = useGetImagesParams();
  const { preferences } = usePreferences();
  const preferenceNames = preferences?.map((preference) => preference.name);

  const [showFormModal, setShowFormModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState('');

  const valid = Boolean(name && preferenceNames && !preferenceNames.includes(name));

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!params) {
      setErrorMessage('Requisição está incompleta. Preencha o painel de controle.');
      setShowErrorModal(true);
      return;
    }

    const response = await savePreferenceTrigger({
      id: 0,
      preferences: params,
      name,
    });

    if ('error' in response) {
      setErrorMessage((response.error as ResponseError).data);
      setShowErrorModal(true);
    } else {
      setName('');
      setShowConfirmationModal(true);
    }
    setShowFormModal(false);
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return {
    showFormModal,
    setShowFormModal,
    showConfirmationModal,
    setShowConfirmationModal,
    showErrorModal,
    setShowErrorModal,
    errorMessage,
    setErrorMessage,
    onNameChange,
    valid,
    handleSubmit,
    isLoading,
  };
}
