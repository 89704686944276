import React from 'react';
import { ListedStudy } from 'helper/studies';
import { Col, Row, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import ActionButton from 'components/Admin/ActionButton';
import { useGetUserQuery } from 'services/api/users';
import { useGetCompanyQuery } from 'services/api/companies';
import { useReexecuteMutation } from 'services/api/products/flux';
import { useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';
import { useGetDownloadLinkQuery } from 'services/api/studies';
import { Link } from 'react-router-dom';
import StatusTag from 'components/Flux/Personalized/Row/StatusTag';

export default function StudyRow({ study }: { study: ListedStudy }) {
  const horizonStart = new Date(study.dt_inicio * 1000).toLocaleDateString('pt-BR');
  const horizonEnd = new Date(study.dt_fim * 1000).toLocaleDateString('pt-BR');
  const lastExecution = study.dt_last_execution === null ? 'Não executado' : new Date(study.dt_last_execution * 1000).toLocaleString('pt-BR');
  const lastResponse = study.dt_last_response === null ? 'Não houve resposta' : new Date(study.dt_last_response * 1000).toLocaleString('pt-BR');
  const { data: userData, error: userError } = useGetUserQuery({ id: study.id_user ?? 0 });
  const {
    data: companyData, error: companyError,
  } = useGetCompanyQuery({ companyId: study.id_empresa ?? 0 });
  const {
    data: downloadLink,
    error: downloadLinkError,
    isLoading: downloadLinkLoading,
  } = useGetDownloadLinkQuery({ id: study.id });
  const [triggerReexecute] = useReexecuteMutation();
  useHandleRequestErrors(userError as ResponseError | undefined);
  useHandleRequestErrors(companyError as ResponseError | undefined);
  useHandleRequestErrors(downloadLinkError as ResponseError | undefined);

  return (
    <div className="list-study-row my-3 px-4">
      <Row>
        <Col md={4}>
          <Stack>
            <div className="study-name">
              { study.ds_nome_estudo }
            </div>
            <div className="study-description">
              { companyData?.razao_social }
            </div>
            <div className="study-description">
              { study.cenarios[0].blocos[0].ds_modelo.split('/').at(-1) }
            </div>
            <div className="study-description">
              { userData?.first_name }
              {' '}
              { userData?.last_name }
            </div>
          </Stack>
        </Col>
        <Col md={4}>
          <div className="horizon">
            { horizonStart }
            {' -- '}
            { horizonEnd }
          </div>
        </Col>
        <Col md={4}>
          <Stack className="study-status">
            <StatusTag study={study} />
            <div>
              Última Execução:
              {' '}
              { lastExecution }
            </div>
            <div>
              Última Resposta:
              {' '}
              { lastResponse }
            </div>
            <div>
              Número de Execuções:
              {' '}
              { study.vl_execution_counter }
            </div>
            <Row className="mt-1">
              <Col md={1}>
                <Link to={downloadLink || ''}>
                  <ActionButton
                    loading={downloadLinkLoading}
                    onClick={() => {}}
                    extraClassName="study-buttons"
                    tooltipText="Baixe o arquivo de estudo"
                  >
                    <FontAwesomeIcon
                      id={`download-action-${study.id}`}
                      icon={faDownload as IconProp}
                    />
                  </ActionButton>
                </Link>
              </Col>
              <Col md={1}>
                <ActionButton
                  loading={false}
                  onClick={() => {
                    triggerReexecute(study.id);
                  }}
                  extraClassName="study-buttons"
                  tooltipText="Execute o estudo novamente"
                >
                  <FontAwesomeIcon
                    id={`reruns-study-${study.id}`}
                    icon={faPaperPlane as IconProp}
                  />
                </ActionButton>
              </Col>
            </Row>
          </Stack>
        </Col>
      </Row>
    </div>
  );
}
