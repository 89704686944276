import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  selectMFControlPanelRegion,
  setRegion as setRegionRedux,
  selectMFControlPanelPeriod,
  setPeriod as setPeriodRedux,
  selectMFControlPanelModels,
  setModels,
  selectMFControlPanelForecastUnixDate,
  setForecastUnixDate as setForecastUnixDateRedux,
  selectMFControlPanelDateOffset,
  setDateOffset,
  selectMFControlPanelAnomaly,
  setAnomaly as setAnomalyRedux,
  selectMFControlPanelUsed,
  setUsed,
  reset,
  selectMFControlPanelVariable,
  setVariable as setVariableRedux,
} from 'redux/reducers/meteorologyFieldsControlPanelReducer';
import { getUnixTime } from 'date-fns';

export default function useNumericForecastControlPanel() {
  const dispatchControlPanelAction = (action, value) => {
    store.dispatch(action(value));
    store.dispatch(setUsed(true));
  };

  const controlPanelUsed = useSelector(selectMFControlPanelUsed);

  const setRegion = (region: string) => {
    dispatchControlPanelAction(setRegionRedux, region);
  };
  const region = useSelector(selectMFControlPanelRegion);

  const setPeriod = (period: string) => {
    dispatchControlPanelAction(setPeriodRedux, period);
  };
  const period = useSelector(selectMFControlPanelPeriod);

  const models = useSelector(selectMFControlPanelModels);
  const toggleModel = (value: string) => {
    let newModels;

    if (models.includes(value)) {
      newModels = models.filter((model) => (model !== value));
    } else {
      newModels = models.concat(value);
    }

    dispatchControlPanelAction(setModels, newModels);
  };

  const setModelArray = (value: string[]) => {
    dispatchControlPanelAction(setModels, value);
  };

  const setVariable = (variable: string) => {
    dispatchControlPanelAction(setVariableRedux, variable);
    setModelArray([]);
  };
  const variable = useSelector(selectMFControlPanelVariable);

  const setForecastUnixDate = (value: Date | null) => {
    let unixValue;

    if (value !== null) {
      unixValue = getUnixTime(value);
    } else {
      unixValue = value;
    }

    dispatchControlPanelAction(setForecastUnixDateRedux, unixValue);
  };
  const forecastUnixDate = useSelector(selectMFControlPanelForecastUnixDate);
  const resetPreferences = () => {
    store.dispatch(reset());
  };

  const setDateIndex = (value: number) => {
    dispatchControlPanelAction(setDateOffset, value);
  };
  const forecastDateIndex = useSelector(selectMFControlPanelDateOffset);

  const setAnomaly = (anomaly: boolean) => {
    dispatchControlPanelAction(setAnomalyRedux, anomaly);
  };
  const anomaly = useSelector(selectMFControlPanelAnomaly);

  const clearModelSelections = () => {
    setModelArray([]);
  };

  return {
    setRegion,
    region,
    setPeriod,
    period,
    toggleModel,
    setModelArray,
    models,
    setForecastUnixDate,
    forecastUnixDate,
    setDateIndex,
    forecastDateIndex,
    setAnomaly,
    anomaly,
    setVariable,
    variable,
    resetPreferences,
    controlPanelUsed,
    clearModelSelections,
  };
}
