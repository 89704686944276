import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './authReducer';
import baseApi from '../../services/api/base';
import infiniteScrollReducer from './infiniteScrollReducer';
import userModalReducer from './userModalReducer';
import mailerLayoutReducer from './mailerLayoutReducer';
import nfeProcessingReducer from './nfeProcessingReducer';
import sendInvoiceModalReducer from './sendInvoiceModalReducer';
import productKeyReducer from './productsReducer';
import meteorologyFieldsLayoutReducer from './meteorologyFieldsLayoutReducer';
import weatherLayoutReducer from './weatherLayoutReducer';
import sidebarsReducer from './sidebarsReducer';
import meteorologyFieldsControlPanelReducer from './meteorologyFieldsControlPanelReducer';
import meteorologyFieldsComparatorControlPanelReducer from './meteorologyFieldsComparatorControlPanelReducer';
import meteorologyFieldsClassicComparatorReducer from './meteorologyFieldsClassicComparatorControlPanelReducer';
import savePreferencesModalReducer from './savePreferencesModalReducer';
import meteorologyFieldsObservedDataControlPanelReducer
  from './meteorologyFieldsObservedDataControlPanelReducer';
import saveComparatorPreferencesModalReducer from './saveComparatorPreferencesModalReducer';
import imagesRowSizeReducer from './imagesRowSizeReducer';
import flexUploadLayoutReducer from './fluxUploadLayoutReducer';
import fluxPersonalizedLayoutReducer from './fluxPersonalizedLayoutReducer';
import fluxAutomaticControlPanelReducer from './fluxAutomaticControlPanelReducer';
import fluxAutomaticLayoutReducer from './fluxAutomaticLayoutReducer';
import fluxUploadFormReducer from './fluxUploadFormReducer';
import verifyZipFileModalReducer from './verifyZipFileModalReducer';
import fluxPersonalizedFormReducer from './fluxPersonalizedFormReducer';
import fluxAutomaticPreferencesModalReducer from './fluxAutomaticPreferencesModalReducer';
import synopticChartsReducer from './synopticChartsReducer';
import meteorologyWeatherSeasonalForecastControlPanelReducer
  from './meteorologyWeatherSeasonalForecastControlPanelReducer';
import meteorologyWeatherComparatorControlPanelReducer from './meteorologyWeatherComparatorControlPanelReducer';

export default combineReducers({
  auth: authReducer,
  infiniteScroll: infiniteScrollReducer,
  userModal: userModalReducer,
  mailerLayout: mailerLayoutReducer,
  nfeProcessing: nfeProcessingReducer,
  sendInvoiceModal: sendInvoiceModalReducer,
  productKeys: productKeyReducer,
  meteorologyFieldsLayout: meteorologyFieldsLayoutReducer,
  weatherLayout: weatherLayoutReducer,
  sidebars: sidebarsReducer,
  meteorologyFieldsComparatorControlPanel: meteorologyFieldsComparatorControlPanelReducer,
  meteorologyFieldsClassicComparatorControlPanel: meteorologyFieldsClassicComparatorReducer,
  meteorologyFieldsControlPanel: meteorologyFieldsControlPanelReducer,
  meteorologyFieldsObservedDataControlPanel: meteorologyFieldsObservedDataControlPanelReducer,
  savePreferencesModal: savePreferencesModalReducer,
  saveComparatorPreferencesModal: saveComparatorPreferencesModalReducer,
  imagesRowSize: imagesRowSizeReducer,
  flexUploadLayout: flexUploadLayoutReducer,
  fluxPersonalizedLayout: fluxPersonalizedLayoutReducer,
  fluxAutomaticControlPanel: fluxAutomaticControlPanelReducer,
  fluxAutomaticLayout: fluxAutomaticLayoutReducer,
  fluxUploadForm: fluxUploadFormReducer,
  verifyZipFileModal: verifyZipFileModalReducer,
  fluxPersonalizedForm: fluxPersonalizedFormReducer,
  fluxAutomaticPreferencesModal: fluxAutomaticPreferencesModalReducer,
  synopticChart: synopticChartsReducer,
  meteorologyWeatherSeasonalForecastControlPanel:
    meteorologyWeatherSeasonalForecastControlPanelReducer,
  meteorologyWeatherComparatorControlPanel:
    meteorologyWeatherComparatorControlPanelReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});
