import React from 'react';
import { Accordion } from 'react-bootstrap';

export default function DateAccordion() {
  return (
    <Accordion.Item eventKey="date" className="control-panel-item">
      <Accordion.Header>
        Data de rodada
      </Accordion.Header>
      <Accordion.Body>
        Conteúdo Data
      </Accordion.Body>
    </Accordion.Item>
  );
}
