import { GenericResponse } from 'services/api/base';

export type ControlPanelTypes = 'comparador' | 'flux' | 'pnt';

interface RawMember {
  [id: number]: string,
}

interface RawRuntime {
  [id: number]: string,
}

interface RawVariable {
  [id: string]: string,
}

export interface RawControlPanelOption {
  membros: RawMember[],
  modelo_label: string,
  modelo_value: string,
  ndias: string,
  niveis_atm: string[],
  runtimes: RawRuntime[],
  variaveis: RawVariable[],
}

type ControlPanelOptionsResponse = GenericResponse<RawControlPanelOption[]>;

export interface Model {
  label: string,
  value: string,
}

interface Member {
  id: string,
  label: string,
}

interface Runtime {
  id: number,
  label: string,
}

export interface Variable {
  id: string,
  label: string,
}

export interface ControlPanelOption {
  model: Model,
  members: Member[],
  runtimes: Runtime[],
  variables: Variable[],
}

export interface NumericForecastImage {
  model: string
  region: string
  period: string
  base64: string
}

export interface DisplayImage {
  key: string,
  title: string,
  base64: string,
}

export interface DisplayData {
  columnLabels: string[],
  rowLabels: string[],
  imageSets: DisplayImage[][],
}

export type ObservedDataColumn = 'observedData' | 'climatology' | 'anomaly';
export type ObservedDataColumnMap<T> = {
  [key in ObservedDataColumn]: T;
};

export interface RawNFImages {
  [key: string]: string // key: model name, value: base64 image
}

export interface RawNFPeriods {
  [key: string]: RawNFImages // key: period (eg d+1)
}

export interface RawNFRegions {
  [key: string]: RawNFPeriods // key: region (eg BR)
}

export interface RawNumericForecastImages {
  imgs: RawNFRegions
}

export type RawNumericForecastImagesResponse = GenericResponse<RawNumericForecastImages>;

export interface ModelDescription {
  modelo: string,
  data_previsao: string,
  runtime: number,
  membro: number,
  nivel_atm: string,
  var: string,
  rmv: number,
}

export interface ComparisonModels {
  base: ModelDescription,
  confrontante: ModelDescription,
  periodo: string,
}

export interface Comparison {
  id: number,
  user_id: number,
  name: string,
  json: ComparisonModels,
}

export type PopularComparisons = Comparison[];
export type PopularComparisonsResponse = GenericResponse<PopularComparisons>;

export interface ComparisonImages {
  period: string,
  base: string,
  confronting: string,
  differences: string,
}

export interface ComparatorDisplayData {
  columnLabels: string[],
  rowLabels: string[],
  imageSets: string[][],
}

export type RawComparisonImagesResponse = GenericResponse<RawComparisonImages>;

export interface RawComparisonImage {
  [key: string]: string // key: period (eg d+1), value: base64 image
}

export interface RawComparisonImages {
  base: RawComparisonImage,
  confrontante: RawComparisonImage,
  diferencas: RawComparisonImage,
}

export interface RawStatus {
  color: string,
  data_previsao: number,
  id: number,
  id_modelo: number,
  label: string,
  membro: string,
  runtime: string,
  status: string,
  timestamp: number,
  variavel: string
}

export type AvailableComparisonDates = number[]; // Unix timestamps in seconds

export interface RawAvailableComparisonDatesResponse {
  status: number,
  data: number[], // Unix timestamps in seconds
}

export type ComparisonPeriodsResponse = string[];
export type RawComparisonPeriodsResponse = GenericResponse<ComparisonPeriodsResponse>;

export const rawToControlPanelOptions = (
  response: ControlPanelOptionsResponse,
): ControlPanelOption[] => {
  const rawOptions = response.data || [];

  return rawOptions.map((rawOption) => ({
    model: {
      label: rawOption.modelo_label,
      value: rawOption.modelo_value,
    },
    members: rawOption.membros.map((m) => ({
      id: Object.keys(m)[0],
      label: Object.values(m)[0] as string,
    })),
    runtimes: rawOption.runtimes.map((r) => ({
      id: Number(Object.keys(r)[0]),
      label: Object.values(r)[0] as string,
    })),
    variables: rawOption.variaveis.map((v) => ({
      id: Object.keys(v)[0] as string,
      label: Object.values(v)[0] as string,
    })),
  }));
};

export const rawToNumericForecastImage = (
  response: RawNumericForecastImagesResponse | undefined,
): NumericForecastImage[] => {
  const numericForecastImages: NumericForecastImage[] = [];

  if (response?.data?.imgs !== undefined) {
    Object.entries(response.data.imgs).forEach(([region, periods]) => {
      Object.entries(periods).forEach(([period, images]) => {
        Object.entries(images).forEach(([model, base64]) => {
          numericForecastImages.push({
            region,
            period,
            model,
            base64,
          });
        });
      });
    });
  }

  return numericForecastImages;
};

export const rawToComparisonImage = (
  response: RawComparisonImagesResponse | undefined,
  period: string,
): ComparisonImages => ({
  period,
  base: Object.values(response?.data?.base || {})[0],
  confronting: Object.values(response?.data?.confrontante || {})[0],
  differences: Object.values(response?.data?.diferencas || {})[0],
});

export const rawToAvailableComparisonDates = (
  response: RawAvailableComparisonDatesResponse | undefined,
): AvailableComparisonDates => (
  response?.data ? Array.from(new Set(response.data)) : []
);
