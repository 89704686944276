import React from 'react';

import Comparator from 'views/Meteorology/Weather/Comparator';

const comparatorRouter = {
  path: 'comparator',
  element: <Comparator />,
};

export default comparatorRouter;
