import { store } from 'redux/store';
import jwt_decode from 'jwt-decode';
import baseApi, { rawBaseQuery, GenericResponse } from 'services/api/base';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { JWTContent } from 'redux/reducers/authReducer';
import { selectMeteorologyKey } from 'redux/reducers/productsReducer';
import {
  transformGetSeasonalForecastOptionsResponse,
  transformListPreferencesResponse,
} from 'helper/Meteorology/SeasonalForecast';
import {
  GetImagesParams,
  RawGetImagesResponse,
} from 'types/Meteorology/Weather/SeasonalForecast/getImages';

import { SeasonalForecastOptions } from 'types/Meteorology/Weather/SeasonalForecast/getSeasonalForecastOptions';
import { SeasonalForecastPreference } from 'types/Meteorology/Weather/SeasonalForecast/listPreferences';
import { SavePreferences } from 'types/Meteorology/Weather/SeasonalForecast/savePreferences';
import { DeletePreferenceParams } from 'types/Meteorology/Weather/SeasonalForecast/deletePreference';

export const seasonalForecastApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSeasonalForecastOptions: build.query<SeasonalForecastOptions, void>({
      query: () => {
        const productKey = selectMeteorologyKey(store.getState());
        return {
          url: `produtos/clima_exclusivo/previsao-sazonal/painel-options/?product_key=${productKey}`,
          method: 'GET',
        };
      },
      transformResponse: transformGetSeasonalForecastOptionsResponse,
    }),
    getSeasonalForecastImages: build.query<RawGetImagesResponse, GetImagesParams>({
      queryFn: async (params, api, extraOptions) => {
        const productKey = selectMeteorologyKey(store.getState());
        const response = await rawBaseQuery(
          {
            url: `produtos/clima_exclusivo/previsao-sazonal/get-image/?product_key=${productKey}`,
            method: 'POST',
            body: {
              variavel: params.variable,
              modelo: params.model,
              area: params.area,
              data: params.date,
              periodo: params.formattedPeriod,
              centro: params.center,
              camadas: params.layers,
            },
          },
          api,
          extraOptions,
        ) as QueryReturnValue<RawGetImagesResponse>;

        const transformedResponse = {
          ...response,
          data: {
            image: response?.data?.image,
          },
        } as QueryReturnValue<RawGetImagesResponse>;

        return transformedResponse;
      },
    }),
    listPreferences: build.query<SeasonalForecastPreference[], void>({
      query: () => {
        const productKey = selectMeteorologyKey(store.getState());
        const userId = jwt_decode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: `produtos/clima_exclusivo/previsao-sazonal/preferencias-list/?product_key=${productKey}`,
          method: 'POST',
          body: { user_id: userId },
        };
      },
      transformResponse: transformListPreferencesResponse,
      providesTags: ['SeasonalForecastPreferences'],
    }),
    deletePreference: build.mutation<GenericResponse<null>, DeletePreferenceParams>({
      query: ({ id }) => {
        const productKey = selectMeteorologyKey(store.getState());
        const userId = jwt_decode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: `produtos/clima_exclusivo/previsao-sazonal/preferencias-delete/?product_key=${productKey}`,
          method: 'DELETE',
          body: { user_id: userId, id },
        };
      },
      invalidatesTags: ['SeasonalForecastPreferences'],
    }),
    savePreferences: build.mutation<GenericResponse<any>, SavePreferences>({
      query: ({
        id, name, preferences,
      }) => {
        const userId = jwt_decode<JWTContent>(store.getState().auth.jwt).user_id;
        const productKey = selectMeteorologyKey(store.getState());

        return {
          url: `produtos/clima_exclusivo/previsao-sazonal/save-preferences/?product_key=${productKey}`,
          method: 'POST',
          body: {
            id,
            user_id: userId,
            name,
            preferencias_json: {
              centro: preferences.center,
              variavel: preferences.variable,
              modelo: preferences.model,
              area: preferences.area,
              data: preferences.date,
              periodo: preferences.formattedPeriod,
              camadas: preferences.layers,
            },
          },
        };
      },
      invalidatesTags: ['SeasonalForecastPreferences'],
    }),
  }),
});

export const {
  useGetSeasonalForecastOptionsQuery,
  useLazyGetSeasonalForecastImagesQuery,
  useListPreferencesQuery,
  useSavePreferencesMutation,
  useDeletePreferenceMutation,
} = seasonalForecastApi;
