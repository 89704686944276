import React from 'react';
import {
  Accordion, Row, Button, Form,
} from 'react-bootstrap';
import { useClosure } from 'utils/hooks';
import useControlPanelUtility from 'hooks/Meteorology/Weather/SeasonalForecast/useControlPanelUtility';
import useLayers from 'hooks/Meteorology/Weather/SeasonalForecast/useLayers';
import useSavePreference from 'hooks/Meteorology/Weather/SeasonalForecast/useSavePreference';
import Preferences from './Preferences';
import Variables from './Variables';
import Models from './Models';
import Area from './Area';
import Period from './Period';
import Dates from './Dates';
import ConfirmationModal from './ConfirmationModal';
import ErrorModal from './ErrorModal';
import FormModal from './FormModal';

export default function SeasonalForecastControlPanel() {
  const { clearFilters } = useControlPanelUtility();
  const { layer, toggleLayers } = useLayers();
  const {
    valid,
    isLoading,
    showFormModal,
    showConfirmationModal,
    showErrorModal,
    errorMessage,
    onNameChange,
    handleSubmit,
    setShowFormModal,
    setShowConfirmationModal,
    setShowErrorModal,
  } = useSavePreference();

  return (
    <div>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={useClosure(setShowConfirmationModal, false)}
      />
      <ErrorModal
        show={showErrorModal}
        onHide={useClosure(setShowErrorModal, false)}
        message={errorMessage}
      />
      <FormModal
        show={showFormModal}
        valid={valid}
        isLoading={isLoading}
        onHide={useClosure(setShowFormModal, false)}
        onNameChange={onNameChange}
        handleSubmit={handleSubmit}
      />
      <Accordion defaultActiveKey="0">
        <Preferences />
        <Variables />
        <Models />
        <Area />
        <Dates />
        <Period />
      </Accordion>
      <Form.Check
        id="anomaly-checkbox"
        className="mt-4"
        type="checkbox"
        label="Mostrar camadas"
        checked={layer}
        onChange={() => toggleLayers()}
      />
      <Row className="pt-5">
        <Button
          variant="primary"
          onClick={useClosure(setShowFormModal, true)}
        >
          Salvar nova preferência
        </Button>
      </Row>
      <Row>
        <Button variant="link" className="clear-filters" onClick={clearFilters}>
          Limpar filtros
        </Button>
      </Row>
    </div>
  );
}
