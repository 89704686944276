import React, { useEffect, useState } from 'react';
import { Stack } from 'react-bootstrap';
import MeteorologyWeatherImageDisplay from 'components/Meteorology/Weather/SeasonalForecast/ImageDisplay';
import PeriodSlider from 'components/Meteorology/Weather/SeasonalForecast/PeriodSlider';
import SeasonalForecastToolbar from 'components/Meteorology/Weather/SeasonalForecast/Toolbar';
import InstructionsModal from 'components/Meteorology/Weather/SeasonalForecast/InstructionsModal';
import { store } from 'redux/store';
import { setCurrentTab } from 'redux/reducers/weatherLayoutReducer';
import { useClosure } from 'utils/hooks';

export default function SeasonalForecast() {
  const [showInstructions, setShowInstructions] = useState(false);

  useEffect(() => {
    store.dispatch(setCurrentTab('seasonal-forecast'));
  }, []);

  return (
    <div>
      <InstructionsModal
        show={showInstructions}
        onHide={useClosure(setShowInstructions, false)}
      />
      <Stack className="m-4">
        <div className="d-md-flex justify-content-between align-items-center">
          <div className="flex-grow-1">
            <PeriodSlider />
          </div>
          <div>
            <SeasonalForecastToolbar
              onInstructionsClick={useClosure(setShowInstructions, true)}
            />
          </div>
        </div>
        <MeteorologyWeatherImageDisplay />
      </Stack>
    </div>
  );
}
