import React from 'react';
import { Accordion } from 'react-bootstrap';
import ModelAccordion from './ModelAccordion';
import DateAccordion from './DateAccordion';
import PeriodAccordion from './PeriodAccordion';
import './index.scss';

export default function Model({ modelIndex } : {
  modelIndex: number;
}) {
  const title = `Modelo ${modelIndex + 1}`;
  const accordionId = `period-accordion-${modelIndex}`;

  return (
    <div>
      <h6 className="model-header">{title}</h6>
      <Accordion defaultActiveKey="0">
        <ModelAccordion modelIndex={modelIndex} />
        <DateAccordion />
        <PeriodAccordion modelIndex={modelIndex} accordionId={accordionId} />
      </Accordion>
    </div>
  );
}
