import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  selectMFControlPanelAnomaly,
  selectMFControlPanelDateOffset,
  selectMFControlPanelForecastUnixDate,
  selectMFControlPanelModels,
  selectMFControlPanelPeriod,
  selectMFControlPanelRegion,
  selectMFControlPanelVariable,
} from 'redux/reducers/meteorologyFieldsControlPanelReducer';
import { NumericForecastImage } from 'helper/products/meteorology';
import { useLazyGetNumericForecastImagesQuery } from 'services/api/Meteorology';
import useMaxOffset from './useMaxOffset';
import useImageRowSize from '../useImageRowSize';

export default function useImages(multiMapMode: boolean) {
  const { rowSize, setRowSize, setMaxRowSize } = useImageRowSize();
  const rowSizeRef = useRef(rowSize);
  const [images, setImages] = useState<NumericForecastImage[]>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [areAllImagesFetched, setAreAllImagesFetched] = useState(false);
  const forecastUnixDate = useSelector(selectMFControlPanelForecastUnixDate);
  const models = useSelector(selectMFControlPanelModels);
  const region = useSelector(selectMFControlPanelRegion);
  const basePeriod: string = useSelector(selectMFControlPanelPeriod);
  const offset: number = useSelector(selectMFControlPanelDateOffset) || 0;
  const anomaly: boolean = useSelector(selectMFControlPanelAnomaly);
  const variable: string = useSelector(selectMFControlPanelVariable);

  const [trigger, { error }] = useLazyGetNumericForecastImagesQuery();

  const maxOffset = useMaxOffset();

  useEffect(() => {
    rowSizeRef.current = rowSize;
  }, [rowSize]);

  useEffect(() => {
    let canceled = false;
    let maxRowSize = 0;

    if (forecastUnixDate && region && basePeriod && models.length > 0) {
      setIsFetching(true);
      setAreAllImagesFetched(false);

      const newImages: NumericForecastImage[] = [];
      const addImages = async (params) => {
        const res = await trigger(params, true);
        const fetchedImages = res.data;

        if (canceled) return;

        if (fetchedImages) {
          newImages.push(...fetchedImages);
          const newImagesArray = [...newImages];
          setImages(newImagesArray);

          if (fetchedImages.length > maxRowSize) maxRowSize = fetchedImages.length;

          if (multiMapMode && models.length > 1) {
            const newRowSize = Math.min(maxRowSize, rowSizeRef.current);
            setMaxRowSize(maxRowSize);
            setRowSize(newRowSize);
          } else {
            const newRowSize = Math.min(newImages.length, rowSizeRef.current);
            setMaxRowSize(newImages.length);
            setRowSize(newRowSize);
          }
        }
        setIsFetching(false);
      };

      if (multiMapMode) {
        const initial = (basePeriod && basePeriod[0] === 'd') ? 1 : 0;
        const imagePromises: Promise<void>[] = [];
        for (let i = initial; i < maxOffset + 1; i += 1) {
          const period = basePeriod.replace(/\d+/, i.toString(10));
          imagePromises.push(addImages({
            forecastUnixDate, models, region, variable, period, anomaly,
          }));
        }
        Promise.all(imagePromises).finally(() => {
          setAreAllImagesFetched(true);
        });
      } else {
        const period = basePeriod.replace(/\d+/, offset.toString(10));
        addImages({
          forecastUnixDate, models, region, variable, period, anomaly,
        }).finally(() => {
          setAreAllImagesFetched(true);
        });
      }
    } else {
      setImages([]);
      setIsFetching(false);
      setAreAllImagesFetched(false);
    }

    return () => {
      canceled = true;
    };
  }, [
    setRowSize,
    setMaxRowSize,
    multiMapMode,
    anomaly,
    maxOffset,
    basePeriod,
    offset,
    forecastUnixDate,
    models,
    region,
    trigger,
    variable,
  ]);

  return {
    images, isFetching, error, areAllImagesFetched,
  };
}
