import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import useArea from 'hooks/Meteorology/Weather/SeasonalForecast/useArea';

export default function Area() {
  const {
    area,
    areas,
    setArea,
  } = useArea();

  return (
    <Accordion.Item eventKey="area" className="control-panel-item">
      <Accordion.Header>
        Área de plotagem
      </Accordion.Header>
      <Accordion.Body>
        {areas.map((v) => (
          <Card.Body key={v.id}>
            <Button
              key={v.id}
              variant="link"
              onClick={() => setArea(v.id)}
              className={`w-100 btn-control-panel ${area === v.id ? 'btn-control-panel-active' : ''}`}
            >
              {v.label}
            </Button>
          </Card.Body>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
