import React from 'react';
import { useSelector } from 'react-redux';
import { selectWeatherLayoutCurrentTab } from 'redux/reducers/weatherLayoutReducer';
import SeasonalForecastControlPanel from './SeasonalForecast';
import ComparatorControlPanel from './Comparator';

export default function ControlPanel() {
  const currentTab = useSelector(selectWeatherLayoutCurrentTab);

  return (
    <div>
      {currentTab === 'seasonal-forecast' && <SeasonalForecastControlPanel />}
      {currentTab === 'comparator' && <ComparatorControlPanel />}
    </div>
  );
}
