import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WeatherLayoutState {
  currentTab: string
}

const initialState: WeatherLayoutState = {
  currentTab: 'oscillations',
};

export const weatherLayoutSlice = createSlice({
  name: 'weatherLayout',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export const selectWeatherLayoutCurrentTab = (state: any) => (
  state.weatherLayout.currentTab
);
/* eslint-enable @typescript-eslint/no-explicit-any */
export const {
  setCurrentTab,
} = weatherLayoutSlice.actions;

export default weatherLayoutSlice.reducer;
