import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import useClassicComparatorVariable from 'utils/hooks/meteorology/classicComparator/useClassicComparatorVariable';
import buildAccordionClickFunction from 'helper/buildAccordionClickFunction';

import useControlPanelOptions from 'utils/hooks/meteorology/useControlPanelOptions';

export default function VariableSeletor() {
  const { variables } = useControlPanelOptions('comparador');
  const { variable, setVariable } = useClassicComparatorVariable();
  const closeAccordion = buildAccordionClickFunction('variable-selector');

  return (
    <Accordion.Item
      id="variable-selector"
      eventKey="variable"
      className="control-panel-item"
    >
      <Accordion.Header>Variável</Accordion.Header>
      <Accordion.Body>
        {variables.map((v) => (
          <Card.Body key={v.id}>
            <Button
              key={v.id}
              variant="link"
              onClick={() => {
                setVariable(v.id);
                closeAccordion();
              }}
              className={`w-100 btn-control-panel ${variable === v.id ? 'btn-control-panel-active' : ''}`}
            >
              {v.label}
            </Button>
          </Card.Body>
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
