import React from 'react';
import {
  Button,
  Col,
  Modal,
  Row,
} from 'react-bootstrap';

export default function ConfirmationModal({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) {
  return (
    <Modal show={show} onHide={onHide} className="confirmation-dialog-modal">
      <Modal.Header closeButton className="confirmation-dialog-modal-header bg-success" />
      <Modal.Body className="my-3 mx-auto confirmation-dialog-modal-body">
        <p>
          As edições foram salvas
          com sucesso!
        </p>
      </Modal.Body>
      <Row className="justify-content-center mb-3 mt-3">
        <Col className="col-md-2">
          <Button
            variant="success"
            onClick={onHide}
          >
            Ok
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
