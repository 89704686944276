import { useDispatch } from 'react-redux';
import { setPeriodOffset } from 'redux/reducers/meteorologyWeatherSeasonalForecastControlPanelReducer';
import useSelectionPeriods from 'hooks/Meteorology/Weather/SeasonalForecast/useSelectionPeriods';

export default function useSliderProps() {
  const { isMonthly, monthPeriods, trimesterPeriods } = useSelectionPeriods();
  const dispatch = useDispatch();

  const maxValue = isMonthly ? monthPeriods.length - 1 : trimesterPeriods.length - 1;
  return {
    sliderMax: maxValue,
    sliderMin: 0,
    sliderOnChange: (value) => dispatch(setPeriodOffset(value)),
  };
}
