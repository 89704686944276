import React from 'react';
import { Modal } from 'react-bootstrap';

export default function ErrorModal({
  show,
  onHide,
  message,
}: {
  show: boolean;
  onHide: () => void;
  message: string;
}) {
  return (
    <Modal show={show} onHide={onHide} className="confirmation-dialog-modal">
      <Modal.Header closeButton className="confirmation-dialog-modal-header bg-danger" />

      <Modal.Body className="my-3 mx-auto confirmation-dialog-modal-body">
        <div>
          <p>
            Ocorreu um erro ao salvar a Preferência. Verifique os parâmetros e tente novamente:
          </p>
          <p>
            Erro:
            <b>
              {' '}
              {message}
            </b>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
