import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import useSelectionPeriods from 'hooks/Meteorology/Weather/SeasonalForecast/useSelectionPeriods';

export default function Period() {
  const {
    setPeriodAsMonth,
    setPeriodAsTrimester,
    isMonthly,
    isTrimesterly,
  } = useSelectionPeriods();

  return (
    <Accordion.Item eventKey="period" className="control-panel-item">
      <Accordion.Header>
        Período de seleção
      </Accordion.Header>
      <Accordion.Body>
        <Button
          variant="link"
          className={`text-center 
                      btn-control-panel 
                      period-pills 
                      ${isMonthly ? 'btn-control-panel-active' : ''}
                    `}
          onClick={setPeriodAsMonth}
        >
          Mensal
        </Button>
        <Button
          variant="link"
          className={`text-center 
                      btn-control-panel 
                      period-pills 
                      ${isTrimesterly ? 'btn-control-panel-active' : ''}
                    `}
          onClick={setPeriodAsTrimester}
        >
          Trimestral
        </Button>
      </Accordion.Body>
    </Accordion.Item>
  );
}
