import React from 'react';
import { useSelector } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import { parse, format } from 'date-fns';

import DatePicker from 'components/DatePicker';
import { store } from 'redux/store';
import {
  setDate,
  selectMWSeasonalForecastControlPanelDate,
} from 'redux/reducers/meteorologyWeatherSeasonalForecastControlPanelReducer';

export default function Dates() {
  const dateString = useSelector(selectMWSeasonalForecastControlPanelDate);
  const date = dateString ? parse(dateString, 'yyyyMMdd', new Date()) : null;

  function dispatchDate(newDate: Date | null) {
    const formattedDate = newDate ? format(newDate, 'yyyyMMdd') : undefined;
    store.dispatch(setDate(formattedDate));
  }

  return (
    <Accordion.Item eventKey="dates" className="control-panel-item">
      <Accordion.Header>
        Data de rodada
      </Accordion.Header>
      <Accordion.Body>
        <DatePicker
          selected={date}
          onChange={(d) => dispatchDate(d)}
          maxDate={new Date()}
          inline
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}
