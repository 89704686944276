const SHORTENED_TAB_PATHS = [
  '/meteorology/weather/oscillations/',
];

export default function shortenedTabPath(path: string) {
  for (let i = 0; i < SHORTENED_TAB_PATHS.length; i += 1) {
    if (path.startsWith(SHORTENED_TAB_PATHS[i])) {
      return SHORTENED_TAB_PATHS[i];
    }
  }

  return path;
}
