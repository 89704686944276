import React, { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import useOverviewSummaryEdit from 'hooks/Meteorology/Weather/Overview/useOverviewSummaryEdit';
import AlertsCollection from 'components/AlertsCollection';
import RichTextEditor from 'components/RichTextEditor';

export default function SummaryForm({
  overview, setOverview,
}: {
  overview: string,
  setOverview: (newOverview: string) => void,
}) {
  const {
    submit, summary, setSummary, isLoading, requestSuccess, validated,
  } = useOverviewSummaryEdit(setOverview);

  useEffect(() => {
    setSummary(overview);
  }, [overview, setSummary]);

  return (
    <Form onSubmit={submit} noValidate>
      <fieldset disabled={false}>
        <Row>
          <Col>
            <Form.Group>
              {!requestSuccess && (
                <>
                  <Form.Label>Resumo</Form.Label>
                  <RichTextEditor
                    id="summaryId"
                    value={summary}
                    onChange={setSummary}
                  />
                  {!validated && (
                    <p className="text-danger">Resumo não pode ser vazio.</p>
                  )}
                </>
              )}
              {requestSuccess && (
                <AlertsCollection
                  alerts={[{
                    title: 'Salvo',
                    message: 'Resumo salvo com sucesso!',
                    variant: 'success',
                  }]}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex align-items-center flex-row-reverse mt-3">
          {!requestSuccess && (
            <Button disabled={isLoading} type="submit" variant="primary">
              {isLoading ? <Spinner size="sm" className="mx-3" /> : 'Salvar'}
            </Button>
          )}
        </div>
      </fieldset>
    </Form>
  );
}
