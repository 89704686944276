import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectMFClassicComparatorInitialDateDiff,
  selectMFClassicComparatorFinalDateDiff,
} from 'redux/reducers/meteorologyFieldsClassicComparatorControlPanelReducer';
import { GetImagesParams } from 'types/Meteorology/Fields/ClassicComparator';
import useGetPossibleIntervalsParams from './useGetPossibleIntervalsParams';

const hasUndefinedValue = (params: GetImagesParams | undefined) => (
  params === undefined || params.initialDateDiff === undefined
    || params.finalDateDiff === undefined
);

export default function useGetImagesParams() {
  const possibleIntervalsParams = useGetPossibleIntervalsParams();
  const initialDateDiff = useSelector(selectMFClassicComparatorInitialDateDiff);
  const finalDateDiff = useSelector(selectMFClassicComparatorFinalDateDiff);

  const params: GetImagesParams | undefined = useMemo(() => (possibleIntervalsParams ? {
    ...possibleIntervalsParams,
    initialDateDiff,
    finalDateDiff,
  } : undefined), [
    possibleIntervalsParams,
    initialDateDiff,
    finalDateDiff,
  ]);

  return hasUndefinedValue(params) ? undefined : params;
}
