import React from 'react';

import SeasonalForecast from 'views/Meteorology/Weather/SeasonalForecast';

const seasonalForecastRouter = {
  path: 'seasonal-forecast',
  element: <SeasonalForecast />,
};

export default seasonalForecastRouter;
