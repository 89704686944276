import { store } from 'redux/store';
import baseApi from 'services/api/base';
import { transformGetImagesResponse, transformGetPossibleIntervalsResponse } from 'helper/Meteorology/ClassicComparator';

import {
  GetImagesParams,
  GetPossibleIntervalsParams,
  Images,
  PossibleIntervals,
} from 'types/Meteorology/Fields/ClassicComparator';

export const classicComparatorApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPossibleIntervals: build.query<PossibleIntervals, GetPossibleIntervalsParams>({
      query: (params) => {
        const productKey = store.getState().productKeys.meteorologia;
        return {
          url: `produtos/comparador/possible-intervals-classic/?product_key=${productKey}`,
          method: 'POST',
          body: {
            base: {
              modelo: params.base.model,
              data_previsao: params.base.forecastDateDiff,
              runtime: params.base.runtime,
              membro: params.base.member,
              nivel_atm: 'single_level',
              var: params.base.variable,
              rmv: params.base.rmv,
            },
            confrontante: {
              modelo: params.confronting.model,
              data_previsao: params.confronting.forecastDateDiff,
              runtime: params.confronting.runtime,
              membro: params.confronting.member,
              nivel_atm: 'single_level',
              var: params.confronting.variable,
              rmv: params.confronting.rmv,
            },
          },
        };
      },
      transformResponse: transformGetPossibleIntervalsResponse,
    }),
    getImages: build.query<Images, GetImagesParams>({
      query: (params) => {
        const productKey = store.getState().productKeys.meteorologia;
        return {
          url: `produtos/comparador/execute-comparison-classic/?product_key=${productKey}`,
          method: 'POST',
          body: {
            base: {
              modelo: params.base.model,
              data_previsao: params.base.forecastDateDiff,
              runtime: params.base.runtime,
              membro: params.base.member,
              nivel_atm: 'single_level',
              var: params.base.variable,
              rmv: params.base.rmv,
            },
            confrontante: {
              modelo: params.confronting.model,
              data_previsao: params.confronting.forecastDateDiff,
              runtime: params.confronting.runtime,
              membro: params.confronting.member,
              nivel_atm: 'single_level',
              var: params.confronting.variable,
              rmv: params.confronting.rmv,
            },
            data_inicial_comparacao: params.initialDateDiff,
            data_final_comparacao: params.finalDateDiff,
          },
        };
      },
      transformResponse: transformGetImagesResponse,
    }),
  }),
});

export const {
  useGetPossibleIntervalsQuery,
  useLazyGetPossibleIntervalsQuery,
  useGetImagesQuery,
  useLazyGetImagesQuery,
} = classicComparatorApi;
