import { useCallback } from 'react';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  selectMFClassicComparatorInitialDateDiff,
  setInitialDateDiff as setInitialDateRedux,
  selectMFClassicComparatorFinalDateDiff,
  setFinalDateDiff as setFinalDateDiffRedux,
} from 'redux/reducers/meteorologyFieldsClassicComparatorControlPanelReducer';
import usePossibleIntervals from 'hooks/Meteorology/Fields/ClassicComparator/usePossibleIntervals';
import dateDiffToDate from 'helper/dateDiffToDate';
import simpleFormatDateDifference from 'helper/simpleFormatDateDifference';

export default function useClassicComparatorDates() {
  const { possibleIntervals } = usePossibleIntervals();
  const initialDateDiff = useSelector(selectMFClassicComparatorInitialDateDiff);
  const finalDateDiff = useSelector(selectMFClassicComparatorFinalDateDiff);
  const initialDate = dateDiffToDate(initialDateDiff, possibleIntervals);
  const finalDate = dateDiffToDate(finalDateDiff, possibleIntervals);

  const setInitialDate = useCallback((date: Date | null) => {
    const dateDiff = simpleFormatDateDifference(date);
    store.dispatch(setInitialDateRedux(dateDiff));
  }, []);
  const setFinalDate = useCallback((date: Date | null) => {
    const dateDiff = simpleFormatDateDifference(date);
    store.dispatch(setFinalDateDiffRedux(dateDiff));
  }, []);
  const resetPeriodDates = useCallback(() => {
    store.dispatch(setInitialDateRedux(undefined));
    store.dispatch(setFinalDateDiffRedux(undefined));
  }, []);

  return {
    initialDate,
    setInitialDate,
    finalDate,
    setFinalDate,
    possibleIntervals,
    resetPeriodDates,
  };
}
