import React from 'react';
import DownloadButton from 'components/Meteorology/Fields/NumericForecast/Toolbar/DownloadButton';

export default function Toolbar({
  onDownloadClick,
  downloadDisabled,
}: {
  onDownloadClick: () => void,
  downloadDisabled: boolean,
}) {
  return (
    <div className="d-flex justify-content-end">
      <DownloadButton onClick={onDownloadClick} disabled={downloadDisabled} />
    </div>
  );
}
