import React from 'react';
import {
  Card,
  Col,
  Modal,
  Row,
} from 'react-bootstrap';

export default function InstructionsModal({
  show,
  onHide,
}: {
  show: boolean,
  onHide: () => void,
}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      centered
      className="confirmation-dialog-modal"
    >
      <Modal.Header
        closeButton
        className="confirmation-dialog-modal-header"
      >
        <Modal.Title className="ms-4">
          INSTRUÇÕES
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-5">
        <Row>
          <Col md={5}>
            <Card className="shadow-sm forecast-instruction-card mb-3">
              <Card.Header>NOVA PREVISÃO</Card.Header>
              <Card.Body className="px-4">
                Selecione as variáveis no painel de controle ao lado e sua
                previsão aparecerá na tela. Lembre que você sempre tem a opção
                de salvar a previsão para rápido acesso.
              </Card.Body>
            </Card>
            <Card className="shadow-sm forecast-instruction-card mb-3 mb-md-0">
              <Card.Header>PREFERÊNCIAS SALVAS</Card.Header>
              <Card.Body className="px-4">
                Se você costuma buscar sempre as mesmas previsões, essa
                funcionalidade é para você! Criamos uma área para que possa
                salvar buscas frequentes, assim você pode visualizá-las quando
                abrir o comparar e rapidamente selecionar a que deseja ver em
                detalhe. Para configurar preferências você seleciona no painel
                de controle ao lado a previsão que deseja ver e clica em salvar
                nova preferência. Ao salvar, você vai poder indicar um nome
                personalizado. O número de preferências salvas é ilimitado.
              </Card.Body>
            </Card>
          </Col>
          <Col md={7}>
            <Card className="shadow-sm forecast-instruction-card h-100">
              <Card.Header>NAVEGAÇÃO</Card.Header>
              <Card.Body className="px-4">
                No canto superior esquerdo da página, você vai encontrar o dia
                para o qual a previsão está sendo feita seguido da data de
                previsão inicial selecionada. A barra de rolagem ajuda você a
                navegar entre as previsões rapidamente. Nas extremidades estão
                as datas de início e fim do horizonte. As setas podem também
                ser usadas para navegar entre os dias com mais precisão.
                Navegue entre os diferentes modos de visualização de sua
                previsão através destes botões no canto superior esquerdo da
                tela. As opções são: single model e multi model. Aquele que
                aparecer na cor azul escuro, será o modo selecionado. Faça
                download dos mapas separadamente de uma única vez, dos GIFs
                (quando aplicável) e da tela com todos os mapas de sua pesquisa
                como uma imagem única.
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
