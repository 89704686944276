import React from 'react';

import WeatherLayout from 'components/Layout/Meteorology/Weather';
import oscillationsRouter from './OscillationsRouter';
import seasonalForecastRouter from './SeasonalForecastRouter';
import comparatorRouter from './ComparatorRouter';

const weatherRouter = {
  path: 'weather',
  element: <WeatherLayout />,
  children: [
    oscillationsRouter,
    seasonalForecastRouter,
    comparatorRouter,
  ],
};

export default weatherRouter;
