import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';

import './index.scss';
import { Link } from 'react-router-dom';
import { selectUserIsAdmin } from 'redux/reducers/authReducer';
import horizontalLogo from 'assets/logo-h.webp';
import soloLogo from 'assets/logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useClosure } from 'utils/hooks';
import versionString from 'helper/version';
import useLeftCollapse from 'utils/hooks/sidebars/useLeftCollapse';
import logout from 'utils/logout';

export default function Sidebar() {
  const [collapsed, setCollapsed] = useLeftCollapse();
  const [productsOpen, setProductsOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);
  const [meteorologyOpen, setMeteorologyOpen] = useState(false);
  const [fluxOpen, setFluxOpen] = useState(false);
  const navigate = useNavigate();
  const admin = useSelector(selectUserIsAdmin);
  const collapseIcon: IconProp = collapsed ? faChevronRight : faChevronLeft;

  return (
    <div className={`d-flex flex-column sidebar-container${collapsed ? ' collapsed' : ''}`}>
      <Link to="home" className="d-flex align-items-center justify-content-center py-5 mb-5 link-body-emphasis text-decoration-none">
        <img id="logo" className="w-75" src={soloLogo} alt="Logo" style={{ display: collapsed ? '' : 'none' }} />
        <img id="horizontal-logo" className="w-75" src={horizontalLogo} alt="Logo" style={{ display: collapsed ? 'none' : '' }} />
      </Link>

      {!collapsed && (
        <>
          <ul className="list-unstyled ps-0">
            <li className="mb-1">
              <div className="d-grid gap-2">
                <Link to="home" className="btn btn-toggle d-inline-flex align-items-center rounded-0 border-0 collapsed no-arrow">Home</Link>
              </div>
            </li>
            <li className="mb-1">
              <div className="d-grid gap-2">
                <Button className="btn btn-toggle btn-toggle-arrow d-inline-flex align-items-center rounded-0 border-0 collapsed" onClick={() => setProductsOpen(!productsOpen)} aria-controls="products-collapse" aria-expanded={productsOpen}>Produtos</Button>
              </div>

              <Collapse in={productsOpen}>
                <div id="products-collapse">
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a href="https://horizon.ampereconsultoria.com.br/" target="blank" className="link-body-emphasis d-inline-flex text-decoration-none">Horizon</a></li>
                    <li>
                      <Link to="fileviewer" className="link-body-emphasis d-inline-flex text-decoration-none">
                        Meus Arquivos
                      </Link>
                    </li>
                    <li>
                      <Button
                        className="small btn btn-toggle btn-toggle-arrow d-inline-flex align-items-center rounded-0 border-0 collapsed w-100 btn-subitems small"
                        onClick={() => setFluxOpen(!fluxOpen)}
                        aria-controls="flux-collapse"
                        aria-expanded={fluxOpen}
                      >
                        Flux
                      </Button>
                    </li>
                    <Collapse in={fluxOpen}>
                      <div id="flux-collapse">
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                          <li>
                            <Link to="/flux/videos/categories" className="link-body-emphasis d-inline-flex text-decoration-none">
                              <div className="w-100">Vídeos</div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/flux/upload" className="link-body-emphasis d-inline-flex text-decoration-none">
                              <div className="w-100">Upload</div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/flux/personalized" className="link-body-emphasis d-inline-flex text-decoration-none">
                              <div className="w-100">Personalizado</div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/flux/automatic/classic" className="link-body-emphasis d-inline-flex text-decoration-none">
                              <div className="w-100">Automático</div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/flux/about" className="link-body-emphasis d-inline-flex text-decoration-none">
                              <div className="w-100">Sobre</div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Collapse>
                    <li>
                      <Button className="small btn btn-toggle btn-toggle-arrow d-inline-flex align-items-center rounded-0 border-0 collapsed w-100 btn-subitems small" onClick={() => setMeteorologyOpen(!meteorologyOpen)} aria-controls="meteorology-collapse" aria-expanded={meteorologyOpen}>Meteorologia</Button>
                    </li>
                    <Collapse in={meteorologyOpen}>
                      <div id="meteorology-collapse">
                        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1">
                          <li>
                            <Link to="/meteorology/videos/categories" className="link-body-emphasis d-inline-flex text-decoration-none">
                              <div className="w-100">Vídeos</div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/meteorology/fields/observeddata" className="link-body-emphasis d-inline-flex text-decoration-none">
                              <div className="w-100">Campos Meteorológicos</div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/meteorology/weather/oscillations/" className="link-body-emphasis d-inline-flex text-decoration-none">
                              <div className="w-100">Clima</div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Collapse>
                  </ul>
                </div>
              </Collapse>
            </li>

            {admin && (
              <li className="mb-1">
                <div className="d-grid gap-2">
                  <Button className="btn btn-toggle btn-toggle-arrow d-inline-flex align-items-center rounded-0 border-0 collapsed" onClick={() => setAdminOpen(!adminOpen)} aria-controls="admin-collapse" aria-expanded={adminOpen}>Administração</Button>
                </div>

                <Collapse in={adminOpen}>
                  <div id="admin-collapse">
                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li>
                        <Link to="/admin/users" className="link-body-emphasis d-inline-flex text-decoration-none">
                          Usuários
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/companies" className="link-body-emphasis d-inline-flex text-decoration-none">
                          Empresas
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/financial" className="link-body-emphasis d-inline-flex text-decoration-none">
                          Financeiro
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/mailer/reports" className="link-body-emphasis d-inline-flex text-decoration-none">
                          Mailer
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Collapse>
              </li>
            )}
          </ul>

          <div className="flex-fill" />

          <div className="pb-4">
            <ul className="list-unstyled ps-0">
              <li className="mb-1">
                <div className="d-grid gap-2">
                  <Button
                    onClick={() => logout(navigate, { title: '', message: 'Logout realizado com sucesso.', variant: 'success' })}
                    className="btn btn-toggle d-inline-flex align-items-center rounded-0 border-0 collapsed no-arrow"
                  >
                    Sair
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </>
      )}

      <div className="d-flex flex-row align-items-center mt-auto justify-content-between">
        {!collapsed && (
          <small className="version-number ms-2 mb-2">
            {versionString()}
          </small>
        )}
        <button
          type="button"
          aria-label="collapse-button"
          className="btn-collapse m-2"
          onClick={useClosure(setCollapsed, !collapsed)}
        >
          <FontAwesomeIcon icon={collapseIcon} />
        </button>
      </div>
    </div>
  );
}
