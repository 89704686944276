import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap';
import useMassMailForm from 'utils/hooks/mailer/useMassMailForm';
import { useClosure, useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';
import { SendMassMailBodyType, useListMailerProductsQuery } from 'services/api/mailer';
import { MASS_MAIL_BODY_TYPE_OPTIONS } from 'helper/mailer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { selectMailerLayoutIsDanger, setIsDanger } from 'redux/reducers/mailerLayoutReducer';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import RichTextEditor from 'components/RichTextEditor';
import CloseButton from '../../../../CloseButton';
import AlertsCollection from '../../../../AlertsCollection';
import CompanySelect from '../../ReportForm/CompanySelect';
import MassMailPreview from '../Preview';
import './index.scss';

export function checkElementSelection(idArray: number[], selectedId: number) {
  return idArray.includes(selectedId);
}

export function switchElement(
  idArray: number[],
  selectedId: number,
  switcher: (ids: number[]) => void,
) {
  return () => {
    const index = idArray.findIndex((element) => element === selectedId);
    if (index >= 0) {
      switcher(idArray.filter((element) => element !== selectedId));
    } else {
      switcher([...idArray, selectedId]);
    }
  };
}

export function switchAllElements(
  idArray: number[],
  selectedIds: number[],
  switcher: (ids: number[]) => void,
) {
  return () => {
    if (selectedIds.length === idArray.length) {
      switcher([]);
    } else {
      switcher(idArray);
    }
  };
}

export default function MassEmailNew({ close }: { close: () => void }) {
  const {
    alerts,
    productIds: selectedProductIds,
    setProductIds: setSelectedProductIds,
    fileRef,
    updateFile,
    body,
    setBody,
    bodyType,
    setBodyType,
    showPreview,
    setShowPreview,
    isTest,
    setIsTest,
    companyIds,
    setCompanyIds,
    showCompanySelect,
    previewButtonDisabled,
    submissionDisabled,
    clearInputsDisabled,
    clearInputs,
    massMailPreviewParams,
    submitMassMail,
    canCancelSubmitMassMail,
    cancelSubmitMassMail,
    companies,
    isLoading,
    subject,
    setSubject,
  } = useMassMailForm();
  const [validated, setValidated] = useState(false);
  const showNoProductsWarning = selectedProductIds.length === 0 && validated;
  const isDanger = useSelector(selectMailerLayoutIsDanger);
  const headerClass = isDanger ? 'mass-email-header-danger' : 'mass-email-header';
  const {
    data: productsResponse,
    isLoading: productsLoading,
    error: productsError,
  } = useListMailerProductsQuery();
  const products = productsResponse?.data || [];
  const productIds = products?.map((product) => (product.id));

  useHandleRequestErrors(productsError as ResponseError);

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setBodyType(value as SendMassMailBodyType);
  };

  const submitForm = (event: React.FormEvent) => {
    event.preventDefault();
    setValidated(true);
    if (!submissionDisabled) {
      submitMassMail();
    }
  };

  useEffect(() => {
    store.dispatch(setIsDanger(!isTest));
    return () => {
      store.dispatch(setIsDanger(false));
    };
  }, [isTest]);

  return (
    <Form onSubmit={submitForm} noValidate validated={validated}>
      <div className={`p-3 mb-3 d-flex justify-content-between align-items-end ${headerClass}`}>
        <CloseButton
          id="close-new-report"
          label="Novo E-mail em massa"
          onClick={close}
        />
        <div className="mx-4 d-flex">
          <Form.Check className="me-2" checked={isTest} onChange={() => setIsTest(!isTest)} />
          Envio de testes
        </div>
      </div>
      <AlertsCollection alerts={alerts} />
      <div className="mt-3 mx-3">
        <Row className="align-items-start">
          <Col>
            Selecione os produtos
          </Col>
        </Row>
        {productsLoading && <Spinner />}
        {!productsLoading && (
          <>
            <Row className="align-items-start mt-3">
              <Col>
                <Form.Check
                  className="me-2"
                  label="Todos"
                  checked={selectedProductIds.length === products?.length}
                  onChange={switchAllElements(
                    productIds,
                    selectedProductIds,
                    setSelectedProductIds,
                  )}
                />
              </Col>
            </Row>
            <Row className="align-items-start mt-1">
              {products?.map((product) => (
                <Col md={2} key={product.id.toString()}>
                  <Form.Check
                    className="me-2 mt-2"
                    id={product.id.toString()}
                    label={product.label}
                    checked={checkElementSelection(selectedProductIds, product.id)}
                    onChange={switchElement(selectedProductIds, product.id, setSelectedProductIds)}
                  />
                </Col>
              ))}
              {showNoProductsWarning && (
                <div className="no-products-error-message mt-1">
                  Selecione ao menos um produto
                </div>
              )}
            </Row>
          </>
        )}
        <Row className="mb-4 my-3">
          <Col xs={3}>
            <Form.Control
              multiple
              type="file"
              className="rounded-0"
              ref={fileRef}
              onChange={updateFile}
            />
            <Form.Control.Feedback type="invalid">
              Selecione ao menos um arquivo
            </Form.Control.Feedback>
          </Col>
          <Col xs={3}>
            <Form.Select required value={bodyType || ''} onChange={handleOptionChange} className="rounded-0">
              <option disabled value="">Corpo E-mail</option>
              {MASS_MAIL_BODY_TYPE_OPTIONS.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Selecione o tipo de corpo
            </Form.Control.Feedback>
          </Col>
          <Col xs={6} className="text-end pt-1 pe-5">
            <Button disabled={previewButtonDisabled} onClick={() => setShowPreview(true)}>
              <FontAwesomeIcon icon={faEye as IconProp} className="me-2" />
              Visualizar
            </Button>
          </Col>
        </Row>
      </div>
      <div className="mx-3 mt-2">
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label> Assunto </Form.Label>
              <Form.Control
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Não deixe o assunto do email em branco
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="mt-3 mx-3">
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label> Mensagem </Form.Label>
          <RichTextEditor
            value={body}
            onChange={useClosure(setBody)}
          />
        </Form.Group>
        <Form.Control.Feedback type="invalid">
          Não deixe o corpo do email em branco
        </Form.Control.Feedback>
      </div>
      <div className="mt-4 mx-3 pb-3">
        <Row>
          <Col xs={1}>
            <Button disabled={clearInputsDisabled} onClick={clearInputs}>
              Limpar
            </Button>
          </Col>
          <Col xs={1}>
            <Button disabled={submissionDisabled || canCancelSubmitMassMail} type="submit">
              {canCancelSubmitMassMail && (<Spinner className="me-2" size="sm" />)}
              Enviar
            </Button>
          </Col>
          <Col xs={1}>
            <Button disabled={!canCancelSubmitMassMail} onClick={cancelSubmitMassMail}>
              Cancelar
            </Button>
          </Col>
        </Row>
      </div>
      {showCompanySelect && (
        <CompanySelect
          companies={companies}
          companiesAreLoading={isLoading}
          selectedCompanyIds={companyIds}
          setSelectedCompanyIds={setCompanyIds}
        />
      )}
      <MassMailPreview
        show={showPreview}
        handleClose={() => setShowPreview(false)}
        previewParams={massMailPreviewParams}
      />
    </Form>
  );
}
