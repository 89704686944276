import { useCallback, useMemo } from 'react';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  selectMWSeasonalForecastControlPanelVariable,
  setVariable as setVariableRedux,
} from 'redux/reducers/meteorologyWeatherSeasonalForecastControlPanelReducer';
import useSeasonalForecastOptions from './useSeasonalForecastOptions';

export default function useVariables() {
  const variable = useSelector(selectMWSeasonalForecastControlPanelVariable);

  const { options } = useSeasonalForecastOptions();
  const variables = useMemo(() => options.map((v) => ({
    id: v.id,
    label: v.label,
  })), [options]);

  const setVariable = useCallback((value: string) => {
    store.dispatch(setVariableRedux(value));
  }, []);

  return {
    variables,
    variable,
    setVariable,
  };
}
