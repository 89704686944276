import { GenericResponse } from 'services/api/base';
import { GetSeasonalForecastOptionsResponse, SeasonalForecastOptions } from 'types/Meteorology/Weather/SeasonalForecast/getSeasonalForecastOptions';
import { RawSeasonalForecastPreference, SeasonalForecastPreference } from 'types/Meteorology/Weather/SeasonalForecast/listPreferences';

export const transformGetSeasonalForecastOptionsResponse = (
  response: GetSeasonalForecastOptionsResponse,
): SeasonalForecastOptions => {
  const { data } = response;
  const uniqueVariables = data.map((option) => option.variavel_nome).filter(
    (value, index, self) => self.indexOf(value) === index,
  );
  return uniqueVariables.map((variable) => ({
    id: variable,
    label: data.find((option) => option.variavel_nome === variable)?.variavel.label || '',
    models: data.filter((option) => option.variavel_nome === variable).map((option) => ({
      id: option.modelos.id,
      label: option.modelos.label,
      center: option.modelos.centro,
      months: option.modelos.mensal,
      trimesters: option.modelos.trimestral,
      areas: option.areas,
    })),
  }));
};

export const transformListPreferencesResponse = (
  response: GenericResponse<RawSeasonalForecastPreference[]>,
): SeasonalForecastPreference[] => (
  response.data.map((preference) => ({
    id: preference.id,
    userId: preference.user_id,
    name: preference.name,
    json: {
      area: preference.json.area,
      layers: preference.json.camadas,
      center: preference.json.centro,
      date: preference.json.data,
      model: preference.json.modelo,
      formattedPeriod: preference.json.periodo,
      variable: preference.json.variavel,
    },
  }))
);
