import React from 'react';
import { Accordion } from 'react-bootstrap';
import DatePicker from 'components/DatePicker';
import usePeriod from 'hooks/Meteorology/Weather/Comparator/usePeriod';
import './index.scss';

function renderMonthContent(month, shortMonth) {
  return <span>{shortMonth.toUpperCase()}</span>;
}

export default function PeriodAccordion({ modelIndex, accordionId }: {
  modelIndex: number,
  accordionId: string,
}) {
  const { getModelPeriod, changePeriod } = usePeriod({ modelIndex, accordionId });

  return (
    <Accordion.Item eventKey="period" className="control-panel-item" id={accordionId}>
      <Accordion.Header>
        Período de seleção
      </Accordion.Header>
      <Accordion.Body>
        <DatePicker
          startDate={getModelPeriod().startDate}
          endDate={getModelPeriod().endDate}
          onChange={changePeriod}
          selectsRange
          showMonthYearPicker
          renderMonthContent={renderMonthContent}
          inline
          calendarClassName="seasonal-comparator-period-datepicker"
        />
      </Accordion.Body>
    </Accordion.Item>
  );
}
