import React from 'react';

import useClassicComparatorBaseModel from 'utils/hooks/meteorology/classicComparator/useClassicComparatorBaseModel';
import ModelAccordion, { ModelType } from '../ModelAccordion';

export default function BaseModel() {
  const {
    baseModel,
    setBaseModel,
    baseForecastDateDiff,
    setBaseForecastDateDiff,
    baseRuntime,
    setBaseRuntime,
    baseMember,
    setBaseMember,
    baseRMV,
    setBaseRMV,
  } = useClassicComparatorBaseModel();

  return (
    <ModelAccordion
      id="base-model-accordion"
      title="Modelo Base"
      modelType={ModelType.BASE}
      model={baseModel}
      setModel={setBaseModel}
      dateDiff={baseForecastDateDiff}
      setDateDiff={setBaseForecastDateDiff}
      runtime={baseRuntime}
      setRuntime={setBaseRuntime}
      member={baseMember}
      setMember={setBaseMember}
      rmv={baseRMV}
      setRMV={setBaseRMV}
    />
  );
}
