import React, { useMemo } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import useModel from 'hooks/Meteorology/Weather/Comparator/useModel';
import buildAccordionClickFunction from 'helper/buildAccordionClickFunction';

export default function ModelAccordion({ modelIndex }: {
  modelIndex: number,
}) {
  const { models, getModels, setModels } = useModel();
  const closeAccordion = useMemo(() => buildAccordionClickFunction(`model-${modelIndex}-list`), [modelIndex]);

  return (
    <Accordion.Item eventKey="model" className="control-panel-item" id={`model-${modelIndex}-list`}>
      <Accordion.Header>
        Modelo
      </Accordion.Header>
      <Accordion.Body className="d-grid control-panel-columns column-gap-3">
        {models.map((m) => (
          <Form.Check
            key={m.id}
            id={`model-${modelIndex}-radio-${m.id}`}
            type="radio"
            className="text-uppercase"
            label={m.label}
            onChange={() => {
              setModels(m.id, modelIndex);
              closeAccordion();
            }}
            checked={m.id === getModels(modelIndex)}
          />
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
