import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import {
  selectMWSeasonalForecastControlPanelVariable,
  selectMWSeasonalForecastControlPanelModel,
  selectMWSeasonalForecastControlPanelArea,
  selectMWSeasonalForecastControlPanelDate,
  selectMWSeasonalForecastControlPanelLayers,
} from 'redux/reducers/meteorologyWeatherSeasonalForecastControlPanelReducer';
import { GetImagesParams } from 'types/Meteorology/Weather/SeasonalForecast/getImages';
import useCenter from 'hooks/Meteorology/Weather/SeasonalForecast/useCenter';
import useSelectionPeriods from 'hooks/Meteorology/Weather/SeasonalForecast/useSelectionPeriods';
import useSeasonalForecastOptions from './useSeasonalForecastOptions';

export default function useGetImagesParams(): GetImagesParams | undefined {
  const { options } = useSeasonalForecastOptions();
  const { center } = useCenter();
  const variable = useSelector(selectMWSeasonalForecastControlPanelVariable);
  const model = useSelector(selectMWSeasonalForecastControlPanelModel);
  const area = useSelector(selectMWSeasonalForecastControlPanelArea);
  const { formattedPeriod } = useSelectionPeriods();
  const date = useSelector(selectMWSeasonalForecastControlPanelDate);
  const layers: boolean = useSelector(selectMWSeasonalForecastControlPanelLayers);

  const params = useMemo(() => {
    const validatedVariable = options?.find((v) => v.id === variable);
    const validatedModel = validatedVariable?.models?.find((m) => m.id === model);
    const areaIsValid = validatedModel?.areas?.find((a) => a.id === area) !== undefined;
    const periodIsValid = formattedPeriod && (
      validatedModel?.months?.includes(formattedPeriod)
      || validatedModel?.trimesters?.includes(formattedPeriod)
    );
    const centerIsValid = center !== undefined;
    const paramsAreValid = areaIsValid && periodIsValid && centerIsValid;

    if (!paramsAreValid) return undefined;

    return {
      center,
      variable,
      model,
      area,
      formattedPeriod,
      date,
      layers,
    };
  }, [options, center, variable, model, area, formattedPeriod, date, layers]);

  return params;
}
