import { fromUnixTime } from 'date-fns';

import {
  Images,
  PossibleIntervals,
  RawGetImagesResponse,
  RawGetPossibleIntervalsResponse,
} from 'types/Meteorology/Fields/ClassicComparator';

export const transformGetPossibleIntervalsResponse = (
  res: RawGetPossibleIntervalsResponse,
): PossibleIntervals => (
  res.data.map((v) => fromUnixTime(v))
);

export const transformGetImagesResponse = (
  res: RawGetImagesResponse,
): Images => ({
  base: res.data.base['t+0'],
  confronting: res.data.confrontante['t+0'],
  difference: res.data.diferencas['t+0'],
});
