import { useEffect, useState } from 'react';
import dateDiffToDate from 'helper/dateDiffToDate';
import { GenericResponse } from 'services/api/base';
import { PreferenceList } from 'services/api/Meteorology';

export default function useLoadFirstPreference({
  data,
  preferenceList,
  resetPreferences,
  setPeriod,
  setModelArray,
  setRegion,
  setAnomaly,
  setForecastUnixDate,
  controlPanelUsed,
  setVariable,
}: {
  data: GenericResponse<PreferenceList[], string> | undefined,
  preferenceList: PreferenceList[],
  resetPreferences: () => void,
  setPeriod: (period: string) => void,
  setModelArray: (models: string[]) => void,
  setRegion: (region: string) => void,
  setAnomaly: (anomaly: boolean) => void,
  setForecastUnixDate: (value: Date | null) => void,
  controlPanelUsed: boolean,
  setVariable: (variable: string) => void,
}) {
  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    if (data === undefined || !firstLoad) {
      return;
    }
    setFirstLoad(false);
    if (preferenceList.length === 0 || controlPanelUsed) {
      return;
    }
    const preference = preferenceList[0];
    const today = new Date();
    const date = dateDiffToDate(preference.json.data_prev, [today]) || today;

    resetPreferences();
    setPeriod(preference.json.periodo_interesse);
    setVariable(preference.json.variavel_meteorologica);
    setModelArray(preference.json.modelos);
    setRegion(preference.json.area_plotagem);
    setAnomaly(preference.json.anom);
    setForecastUnixDate(date);
  }, [firstLoad,
    data,
    preferenceList,
    resetPreferences,
    setForecastUnixDate,
    setVariable,
    setModelArray,
    setPeriod,
    setRegion,
    setAnomaly,
    controlPanelUsed,
  ]);
}
