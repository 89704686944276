import React, { useEffect } from 'react';
import { store } from 'redux/store';
import { setCurrentTab } from 'redux/reducers/weatherLayoutReducer';

export default function Comparator() {
  useEffect(() => {
    store.dispatch(setCurrentTab('comparator'));
  }, []);

  return (
    <div className="p-4">
      <div>
        TODO: Toolbar
      </div>
      <div>
        TODO: Image display
      </div>
    </div>
  );
}
