import { useCallback, useMemo } from 'react';

import { ControlPanelOption, ControlPanelTypes } from 'helper/products/meteorology';
import { useControlPanelOptionsQuery } from 'services/api/Meteorology';
import { useHandleRequestErrors } from 'utils/hooks';
import { ResponseError } from 'services/api/base';

export const EMPTY_MODEL_OPTIONS: ControlPanelOption = {
  members: [],
  runtimes: [],
  variables: [],
  model: {
    label: '',
    value: '',
  },
};

export default function useControlPanelOptions(type: ControlPanelTypes) {
  const { data, isFetching, error } = useControlPanelOptionsQuery(type);
  const options = useMemo(() => data || [], [data]);
  const models = useMemo(() => (
    options.map(({ model }) => model)
  ), [options]);
  const variables = useMemo(() => (
    options
      .flatMap((o) => o.variables)
      .filter((var1, i, arr) => arr.findIndex((var2) => var1.id === var2.id) === i)
  ), [options]);

  const getModelOptions = useCallback((modelValue: string) => (
    options.find((o) => o.model.value === modelValue) || EMPTY_MODEL_OPTIONS
  ), [options]);
  const getModelsForVariable = useCallback((variable: string) => (
    options
      .filter((o) => o.variables.some((v) => v.id === variable))
      .map((o) => o.model)
  ), [options]);

  useHandleRequestErrors(error as ResponseError);

  return {
    models,
    variables,
    getModelOptions,
    getModelsForVariable,
    isFetching,
  };
}
