import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MeteorologyFieldsClassicComparatorControlPanelState {
  variable: string | undefined;
  baseModel: string | undefined;
  baseForecastDateDiff: string | undefined;
  baseRuntime: number | undefined;
  baseMember: string | undefined;
  baseRMV: boolean;
  confrontingModel: string | undefined;
  confrontingForecastDateDiff: string | undefined;
  confrontingRuntime: number | undefined;
  confrontingMember: string | undefined;
  confrontingRMV: boolean;
  initialDateDiff: string | undefined;
  finalDateDiff: string | undefined;
  requestTriggered: boolean;
}

const initialState: MeteorologyFieldsClassicComparatorControlPanelState = {
  variable: undefined,
  baseModel: undefined,
  baseForecastDateDiff: undefined,
  baseRuntime: undefined,
  baseMember: undefined,
  baseRMV: false,
  confrontingModel: undefined,
  confrontingForecastDateDiff: undefined,
  confrontingRuntime: undefined,
  confrontingMember: undefined,
  confrontingRMV: false,
  initialDateDiff: undefined,
  finalDateDiff: undefined,
  requestTriggered: false,
};

export const meteorologyFieldsClassicComparatorControlPanelSlice = createSlice({
  name: 'meteorologyFieldsClassicComparatorControlPanel',
  initialState,
  reducers: {
    setVariable: (state, action: PayloadAction<string | undefined>) => {
      state.variable = action.payload;
    },
    setBaseForecastDateDiff: (state, action: PayloadAction<string | undefined>) => {
      state.baseForecastDateDiff = action.payload;
    },
    setBaseModel: (state, action: PayloadAction<string | undefined>) => {
      state.baseModel = action.payload;
    },
    setBaseRuntime: (state, action: PayloadAction<number | undefined>) => {
      state.baseRuntime = action.payload;
    },
    setBaseMember: (state, action: PayloadAction<string | undefined>) => {
      state.baseMember = action.payload;
    },
    setBaseRMV: (state, action: PayloadAction<boolean>) => {
      state.baseRMV = action.payload;
    },
    setConfrontingForecastDateDiff: (state, action: PayloadAction<string | undefined>) => {
      state.confrontingForecastDateDiff = action.payload;
    },
    setConfrontingModel: (state, action: PayloadAction<string>) => {
      state.confrontingModel = action.payload;
    },
    setConfrontingRuntime: (state, action: PayloadAction<number | undefined>) => {
      state.confrontingRuntime = action.payload;
    },
    setConfrontingMember: (state, action: PayloadAction<string | undefined>) => {
      state.confrontingMember = action.payload;
    },
    setConfrontingRMV: (state, action: PayloadAction<boolean>) => {
      state.confrontingRMV = action.payload;
    },
    setInitialDateDiff: (state, action: PayloadAction<string | undefined>) => {
      state.initialDateDiff = action.payload;
    },
    setFinalDateDiff: (state, action: PayloadAction<string | undefined>) => {
      state.finalDateDiff = action.payload;
    },
    setRequestTriggered: (state, action: PayloadAction<boolean>) => {
      state.requestTriggered = action.payload;
    },
    reset: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const selectMFClassicComparatorVariable = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.variable
);
export const selectMFClassicComparatorBaseForecastDateDiff = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.baseForecastDateDiff
);
export const selectMFClassicComparatorBaseModel = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.baseModel
);
export const selectMFClassicComparatorBaseRuntime = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.baseRuntime
);
export const selectMFClassicComparatorBaseMember = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.baseMember
);
export const selectMFClassicComparatorBaseRMV = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.baseRMV
);
export const selectMFClassicComparatorConfrontingForecastDateDiff = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.confrontingForecastDateDiff
);
export const selectMFClassicComparatorConfrontingModel = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.confrontingModel
);
export const selectMFClassicComparatorConfrontingRuntime = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.confrontingRuntime
);
export const selectMFClassicComparatorConfrontingMember = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.confrontingMember
);
export const selectMFClassicComparatorConfrontingRMV = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.confrontingRMV
);
export const selectMFClassicComparatorInitialDateDiff = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.initialDateDiff
);
export const selectMFClassicComparatorFinalDateDiff = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.finalDateDiff
);
export const selectMFClassicComparatorRequestTriggered = (state: any) => (
  state.meteorologyFieldsClassicComparatorControlPanel.requestTriggered
);

export const {
  setVariable,
  setBaseModel,
  setBaseForecastDateDiff,
  setBaseRuntime,
  setBaseMember,
  setBaseRMV,
  setConfrontingModel,
  setConfrontingForecastDateDiff,
  setConfrontingRuntime,
  setConfrontingMember,
  setConfrontingRMV,
  setInitialDateDiff,
  setFinalDateDiff,
  setRequestTriggered,
  reset,
} = meteorologyFieldsClassicComparatorControlPanelSlice.actions;

export default meteorologyFieldsClassicComparatorControlPanelSlice.reducer;
