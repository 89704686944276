import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import TabbedPage from 'components/TabbedPage';
import ControlPanel from 'components/Meteorology/Weather/ControlPanel';
import RightSidebar from 'components/Layout/RightSidebar';

export default function WeatherLayout() {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <>
      <Row>
        <Col className="md-6">
          <h1 className="mb-3">CLIMA</h1>
        </Col>
      </Row>
      <Row style={{ flexWrap: 'nowrap', paddingBottom: '3.3125rem' }}>
        <Col md="auto" className="flex-fill">
          <TabbedPage setControlPanelVisibility={setShowSidebar}>
            <TabbedPage.Tab title="OSCILAÇÕES" path="/meteorology/weather/oscillations/" />
            {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
              <TabbedPage.Tab
                title="PREVISÃO SAZONAL"
                path="/meteorology/weather/seasonal-forecast"
                showControlPanel
              />
            )}
            {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
              <TabbedPage.Tab
                title="COMPARADOR"
                path="/meteorology/weather/comparator"
                showControlPanel
              />
            )}
          </TabbedPage>
        </Col>
        {showSidebar && (
          <Col md="auto">
            <RightSidebar>
              <ControlPanel />
            </RightSidebar>
          </Col>
        )}
      </Row>
      <div className="bottom-bar" />
    </>
  );
}
