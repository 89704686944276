import React, { useEffect } from 'react';

import {
  Button,
  Col, Form, FormCheck, Row, Spinner,
} from 'react-bootstrap';
import AlertsCollection from 'components/AlertsCollection';
import { useLazyGetVideoDataByIdQuery } from 'services/api/videos';
import { onDatePickerChange } from 'helper/controlledField';
import {
  VideoProduct,
  videoProductToPath,
  videoProductToPermission,
} from 'helper/videos';
import useVideoSave from 'utils/hooks/videos/useVideoSave';
import DatePicker from 'components/DatePicker';
import RichTextEditor from 'components/RichTextEditor';

export default function VideoForm({
  id,
  product,
}: {
  id: number | undefined;
  product: VideoProduct;
}) {
  const [trigger, { data }] = useLazyGetVideoDataByIdQuery();
  const video = data?.data;

  const {
    isEditing,
    alerts,
    fileRef,
    tags,
    setTags,
    videoCategory,
    setVideoCategory,
    title,
    setTitle,
    date,
    setDate,
    vimeoIdText,
    onChangeVimeoId,
    richTextValue,
    setRichTextValue,
    videoCategories,
    updateFile,
    submitEnabled,
    submitVideoSave,
    isSaving,
  } = useVideoSave(product, video);

  useEffect(() => {
    if (id) {
      const productPath = videoProductToPath[product];
      trigger({ id, productPath, permissionType: videoProductToPermission[product] });
    }
  }, [id, product, trigger]);

  if (id && !video) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Form onSubmit={submitVideoSave} noValidate>
        <fieldset disabled={false}>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Vimeo ID</Form.Label>
                <Form.Control
                  id="vimeoId"
                  required
                  type="text"
                  value={vimeoIdText}
                  onChange={onChangeVimeoId}
                  disabled={isEditing}
                />
                <Form.Control.Feedback type="invalid">
                  O id do vídeo deve ser um número
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Título</Form.Label>
                <Form.Control
                  id="title"
                  required
                  type="text"
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  O título não pode ficar em branco
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Row>
              <Form.Label>Categoria do vídeo</Form.Label>
            </Row>
            <Row className="mb-3">
              {videoCategories.map((category) => (
                <Col key={category.id}>
                  <FormCheck
                    id={`category-${category.label}`}
                    type="radio"
                    label={category.label}
                    checked={videoCategory === category.id}
                    onChange={() => setVideoCategory(category.id)}
                  />
                </Col>
              ))}
            </Row>
          </Form.Group>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Data</Form.Label>
                <DatePicker
                  id="date"
                  required
                  selected={date}
                  className="form-control"
                  onChange={onDatePickerChange(setDate)}
                />
                <Form.Control.Feedback type="invalid">
                  O campo de data não pode estar em branco
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Tags</Form.Label>
                <Form.Control
                  id="tags"
                  type="text"
                  value={tags}
                  onChange={(event) => setTags(event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={6}>
              <Form.Control
                id="file"
                required
                type="file"
                ref={fileRef}
                onChange={updateFile}
                className="rounded-3"
              />
              <Form.Control.Feedback type="invalid">
                Selecione ao menos um arquivo
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Form.Label>Descrição</Form.Label>
              <RichTextEditor
                id="description"
                value={richTextValue}
                onChange={setRichTextValue}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <AlertsCollection alerts={alerts} />
            </Col>
          </Row>
          <Row className="mx-5">
            <Col md={6} />
            <Col md={5}>
              <Button variant="outline-primary" className="float-end">
                Salvar Rascunho
              </Button>
            </Col>
            <Col md={1}>
              <Button disabled={!submitEnabled || isSaving} type="submit" variant="primary">
                {isSaving ? <Spinner size="sm" className="mx-3" /> : 'Publicar'}
              </Button>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
}
