import {
  addDays, endOfDay, isSameDay, startOfDay,
} from 'date-fns';

interface DatepickerInterval {
  start: Date;
  end: Date;
}

export default function sortedDatesAsIntervals(sortedDates: Date[]): DatepickerInterval[] {
  const intervals: DatepickerInterval[] = [];

  let currentStart = sortedDates[0];
  let currentEnd = currentStart;

  for (let i = 1; i < sortedDates.length; i += 1) {
    const date = sortedDates[i];
    const expectedNextDate = addDays(currentEnd, 1);
    const datesMatch = isSameDay(date, expectedNextDate);

    if (datesMatch) {
      currentEnd = date;
    } else {
      intervals.push({
        start: startOfDay(currentStart),
        end: endOfDay(currentEnd),
      });
      currentStart = date;
      currentEnd = date;
    }
  }

  intervals.push({
    start: startOfDay(currentStart),
    end: endOfDay(currentEnd),
  });

  return intervals;
}
