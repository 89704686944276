import { useMemo } from 'react';
import useGetImagesParams from 'hooks/Meteorology/Weather/SeasonalForecast/useGetImagesParams';
import useImageDownload from 'hooks/useImageDownload';
import useImages from './useImages';

export default function useSeasonalForecastDownload() {
  const params = useGetImagesParams();
  const { image, loading } = useImages();
  const filename = useMemo(() => (
    `seasonal_forecast_${params?.variable}_${params?.model}_${params?.area}_${params?.date}_${params?.formattedPeriod}.png`
  ), [params]);

  const { onDownloadClick, downloadDisabled } = useImageDownload(image || '', loading, filename);

  return {
    onDownloadClick,
    downloadDisabled,
  };
}
