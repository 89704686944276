import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectMFControlPanelVariable } from 'redux/reducers/meteorologyFieldsControlPanelReducer';
import { ControlPanelOption, Model } from 'helper/products/meteorology';

export default function useVariables(
  models: Model[],
  getModelOptions: (modelValue: string) => ControlPanelOption,
) {
  const variable = useSelector(selectMFControlPanelVariable);

  const uniqueVariables = useMemo(() => (
    models
      .map((m) => getModelOptions(m.value))
      .flatMap((o) => o.variables)
      .filter((var1, i, arr) => arr.findIndex((var2) => var1.id === var2.id) === i)
  ), [models, getModelOptions]);

  const selectableModels = useMemo(() => (
    models.filter((m) => (
      getModelOptions(m.value)
        .variables
        .some((v) => v.id === variable)
    ))
  ), [models, getModelOptions, variable]);

  return {
    variables: uniqueVariables,
    selectableModels,
  };
}
