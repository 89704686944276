import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion, Card, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';

import useAvailableDates from 'utils/hooks/meteorology/comparator/useAvailableDates';
import useControlPanelOptions from 'utils/hooks/meteorology/useControlPanelOptions';
import useClassicComparatorDates from 'utils/hooks/meteorology/classicComparator/useClassicComparatorDates';
import formatDateDifference from 'helper/formatDateDifference';
import dateDiffToDate from 'helper/dateDiffToDate';
import DatePicker from 'components/DatePicker';
import { selectMFClassicComparatorVariable } from 'redux/reducers/meteorologyFieldsClassicComparatorControlPanelReducer';
import sortedDatesAsIntervals from 'helper/Shared/sortedDatesAsIntervals';
import buildAccordionClickFunction from 'helper/buildAccordionClickFunction';

export enum ModelType {
  BASE = 'base',
  CONFRONTATIONAL = 'confrontational',
}

const sectionTitleStyle = {
  color: '#c1c1c1',
  fontSize: '18px',
  fontWeight: '600',
};

function ModelRadioButton({
  value,
  label,
  modelType,
  model,
  setModel,
  closeAccordion,
  resetPeriodDates,
  resetModelDependencies,
}: {
  value: string;
  label: string;
  modelType: ModelType;
  setModel: (value: string) => void,
  model: string;
  closeAccordion: () => void;
  resetPeriodDates: () => void;
  resetModelDependencies: () => void;
}) {
  const handleModel = () => {
    if (model !== value) {
      resetPeriodDates();
      resetModelDependencies();
    }
    closeAccordion();
    setModel(value);
  };

  return (
    <Form.Check
      id={`model-${value}-${modelType}`}
      type="radio"
      className="text-uppercase pe-1"
      label={label}
      value={value}
      onChange={handleModel}
      checked={model === value}
    />
  );
}

export default function ModelAccordion({
  modelType,
  title,
  model,
  setModel,
  dateDiff,
  setDateDiff,
  runtime,
  setRuntime,
  member,
  setMember,
  rmv,
  setRMV,
  id,
}: {
  modelType: ModelType;
  title: string;
  model: string;
  setModel: (value: string) => void;
  dateDiff: string;
  setDateDiff: (value: string | undefined) => void;
  runtime: number | undefined;
  setRuntime: (value: number | undefined) => void;
  member: string | undefined;
  setMember: (value: string | undefined) => void;
  rmv: boolean;
  setRMV: (value: boolean) => void;
  id: string;
}) {
  const closeDateAccordion = buildAccordionClickFunction(`${id}-date-options-accordion`);
  const closeRuntimeAccordion = buildAccordionClickFunction(`${id}-runtime-options-accordion`);
  const closeMemberAccordion = buildAccordionClickFunction(`${id}-member-options-accordion`);
  const closeRMVAccordion = buildAccordionClickFunction(`${id}-rmv-options-accordion`);

  const { resetPeriodDates } = useClassicComparatorDates();

  const resetModelDependencies = () => {
    setRuntime(undefined);
    setMember(undefined);
  };

  const variable = useSelector(selectMFClassicComparatorVariable);
  const { getModelOptions, getModelsForVariable, isFetching } = useControlPanelOptions('comparador');

  const modelOptions = useMemo(
    () => getModelsForVariable(variable),
    [variable, getModelsForVariable],
  );
  const availableDates = useAvailableDates(model);
  const availableIntervals = useMemo(
    () => sortedDatesAsIntervals(availableDates),
    [availableDates],
  );
  const {
    runtimes: runtimeOptions,
    members: memberOptions,
  } = useMemo(() => getModelOptions(model), [model, getModelOptions]);

  const date = useMemo(
    () => dateDiffToDate(dateDiff, availableDates),
    [dateDiff, availableDates],
  );
  const setDate = (d: Date | null) => {
    const newDiff = formatDateDifference(d, availableDates);
    if (newDiff !== dateDiff) {
      resetModelDependencies();
      setDateDiff(newDiff);
    }
    closeDateAccordion();
  };

  useEffect(() => {
    const runtimeIsAvailable = runtimeOptions.some((ro) => ro.id === runtime);
    if (!isFetching && !runtimeIsAvailable) {
      setRuntime(undefined);
    }
    if (runtimeOptions.length === 1) {
      setRuntime(runtimeOptions[0].id);
    }
  }, [isFetching, runtime, runtimeOptions, setRuntime]);

  useEffect(() => {
    const memberIsAvailable = memberOptions.some((mo) => mo.id === member);
    if (!isFetching && !memberIsAvailable) {
      setMember(undefined);
    }
    if (memberOptions.length === 1) {
      setMember(memberOptions[0].id);
    }
  }, [isFetching, member, memberOptions, setMember]);

  if (isFetching) {
    return (
      <div className="text-center mb-1">
        <Spinner />
      </div>
    );
  }

  return (
    <Container id={id}>
      <Row className="mt-4">
        <Col className="d-inline-flex justify-content-center">
          <p style={sectionTitleStyle}>{title}</p>
        </Col>
      </Row>
      <Accordion defaultActiveKey="1">
        <Accordion.Item
          eventKey="3"
          className="control-panel-item"
          id={`${id}-model-options-accordion`}
        >
          <Accordion.Header>Modelo</Accordion.Header>
          <Accordion.Body>
            <div>
              {modelOptions.map((m) => (
                <ModelRadioButton
                  key={`modelRadioButton-${m.value}-${modelType}`}
                  modelType={modelType}
                  value={m.value}
                  label={m.label}
                  model={model}
                  setModel={setModel}
                  closeAccordion={buildAccordionClickFunction(`${id}-model-options-accordion`)}
                  resetPeriodDates={resetPeriodDates}
                  resetModelDependencies={resetModelDependencies}
                />
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item
          eventKey="4"
          className="control-panel-item"
          id={`${id}-date-options-accordion`}
        >
          <Accordion.Header>Data de Previsão</Accordion.Header>
          <Accordion.Body className="d-grid">
            <DatePicker
              inline
              selected={date}
              onChange={setDate}
              includeDateIntervals={availableIntervals}
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item
          eventKey="5"
          className="control-panel-item"
          id={`${id}-runtime-options-accordion`}
        >
          <Accordion.Header>Runtime</Accordion.Header>
          <Accordion.Body className="d-grid">
            {runtimeOptions.map((ro) => (
              <Form.Check
                key={ro.id}
                id={`runtime-${ro.id}-${modelType}`}
                type="radio"
                label={ro.label}
                value={ro.id}
                checked={runtime === ro.id}
                onChange={() => {
                  setRuntime(ro.id);
                  closeRuntimeAccordion();
                }}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item
          eventKey="6"
          className="control-panel-item"
          id={`${id}-member-options-accordion`}
        >
          <Accordion.Header>Membro</Accordion.Header>
          <Accordion.Body className="d-grid">
            {memberOptions.map((mo) => (
              <Form.Check
                key={mo.id}
                id={`member-${mo.id}-${modelType}`}
                type="radio"
                label={mo.label}
                value={mo.id}
                checked={member === mo.id}
                onChange={() => {
                  setMember(mo.id);
                  closeMemberAccordion();
                }}
              />
            ))}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item
          eventKey="7"
          className="control-panel-item"
          id={`${id}-rmv-options-accordion`}
        >
          <Accordion.Header>RMV</Accordion.Header>
          <Accordion.Body>
            <Card.Body>
              <Form.Check
                id={`withRMV-${modelType}`}
                type="radio"
                label="Com RMV"
                value={`withRMV-${modelType}`}
                checked={rmv}
                onChange={() => {
                  setRMV(true);
                  closeRMVAccordion();
                }}
              />
              <Form.Check
                id={`withoutRMV-${modelType}`}
                type="radio"
                label="Sem RMV"
                value={`withoutRMV-${modelType}`}
                checked={!rmv}
                onChange={() => {
                  setRMV(false);
                  closeRMVAccordion();
                }}
              />
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}
