import { useEffect } from 'react';

import { ResponseError } from 'services/api/base';
import { useLazyGetPossibleIntervalsQuery } from 'services/api/Meteorology/Fields/ClassicComparator';
import { useHandleRequestErrors } from 'utils/hooks';
import useGetPossibleIntervalsParams from './useGetPossibleIntervalsParams';

export default function usePossibleIntervals() {
  const params = useGetPossibleIntervalsParams();
  const [trigger, { data, error, isFetching }] = useLazyGetPossibleIntervalsQuery();
  const possibleIntervals = data || [];

  useHandleRequestErrors(error as ResponseError);

  useEffect(() => {
    if (params) {
      trigger(params);
    }
  }, [params, trigger]);

  return {
    possibleIntervals,
    isFetching,
  };
}
