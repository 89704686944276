import { useCallback, useMemo } from 'react';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  selectMWComparatorControlPanelArea,
  selectMWComparatorControlPanelVariable,
  setArea as setAreaRedux,
} from 'redux/reducers/meteorologyWeatherComparatorControlPanelReducer';
import useSeasonalComparatorOptions from './useSeasonalComparatorOptions';

export default function useArea() {
  const area = useSelector(selectMWComparatorControlPanelArea);
  const variable = useSelector(selectMWComparatorControlPanelVariable);
  const { options } = useSeasonalComparatorOptions();

  const areas = useMemo(
    () => options.find((v) => v.id === variable)?.areas.map(
      (a) => ({
        id: a.id,
        label: a.label,
      }),
    ) ?? [],
    [options, variable],
  );

  const setArea = useCallback((value: string) => {
    // TODO: Reset model parameters
    store.dispatch(setAreaRedux(value));
  }, []);

  return {
    areas,
    area,
    setArea,
  };
}
