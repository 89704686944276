import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomAlert } from 'components/AlertsCollection';
import {
  selectChart,
  selectDate,
  selectRuntime,
  setImageIndex,
  setImages,
  setImagesAreLoading,
} from 'redux/reducers/synopticChartsReducer';
import { useLazyGetImagesSCQuery } from 'services/api/Meteorology/synopticCharts';
import { Image } from 'helper/products/synopticCharts';

const DEFAULT_ALERT: CustomAlert = {
  title: 'Indisponível',
  message: 'Não há nenhuma imagem disponível para esta data.',
  variant: 'warning',
};

const fetchImage = async (image: Image) => {
  const res = await fetch(image.url);
  const blob = await res.blob();
  return { ...image, url: URL.createObjectURL(blob) } as Image;
};

export default function useGetImages() {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [trigger, {
    data: baseData,
    error,
    isFetching: urlsAreLoading,
  }] = useLazyGetImagesSCQuery();
  const chart = useSelector(selectChart);
  const dateString = useSelector(selectDate);
  const runtime = useSelector(selectRuntime);

  const data = error ? undefined : baseData;
  const alert = error ? DEFAULT_ALERT : undefined;

  useEffect(() => {
    if (chart && dateString && runtime) {
      dispatch(setImages(undefined));
      dispatch(setImageIndex(0));
      trigger({ chartId: chart.id, dateString, runtimeId: runtime.id });
    }
  }, [chart, dateString, runtime, trigger, dispatch]);

  useEffect(() => {
    if (data) {
      setIsFetching(true);
      const fetchImages = async () => {
        const promises = data.map((image) => fetchImage(image));
        const fetchedImages = await Promise.all(promises);
        return fetchedImages;
      };

      fetchImages()
        .then((i) => dispatch(setImages(i)))
        .finally(() => setIsFetching(false));
    }
  }, [data, setIsFetching, dispatch]);

  useEffect(() => {
    dispatch(setImagesAreLoading(urlsAreLoading || isFetching));
  }, [urlsAreLoading, isFetching, dispatch]);

  return { alert };
}
