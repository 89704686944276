import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import useSeasonalForecastDownload from 'hooks/Meteorology/Weather/SeasonalForecast/useSeasonalForecastDownload';

export default function SeasonalForecastToolbar({
  onInstructionsClick,
}: {
  onInstructionsClick: () => void;
}) {
  const { onDownloadClick, downloadDisabled } = useSeasonalForecastDownload();

  return (
    <div className="d-flex">
      <Button
        id="seasonal-forecast-download-button"
        size="sm"
        className="icon-button weather-download-button me-2"
        onClick={onDownloadClick}
        disabled={downloadDisabled}
      >
        <FontAwesomeIcon icon={faDownload as IconProp} />
      </Button>
      <Button
        id="seasonal-forecast-instructions-button"
        size="sm"
        className="icon-button weather-download-button"
        onClick={onInstructionsClick}
      >
        <FontAwesomeIcon icon={faQuestion as IconProp} />
      </Button>
    </div>
  );
}
