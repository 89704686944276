export default [
  {
    acronym: 'AMO', url: 'amo', label: 'Oscilação Multidecadal do Atlântico', left: '70', top: '36',
  },
  {
    acronym: 'PDO', url: 'pdo', label: 'Oscilação Decadal do Pacífico', left: '39', top: '35',
  },
  {
    acronym: 'ENSO', url: 'enso', label: 'El Niño Oscilação Sul', left: '54', top: '51',
  },
  {
    acronym: 'SAD', url: 'sad', label: 'Dipolo do Atlântico Sul', left: '75', top: '66',
  },
  {
    acronym: 'AAO', url: 'aao', label: 'Oscilação Antártica', left: '45', top: '75',
  },
  {
    acronym: 'MJO', url: 'mjo', label: 'Oscilação Madden-Julian', left: '26', top: '52',
  },
  {
    acronym: 'TNA', url: 'tna', label: 'Índice do Atlântico Tropical Norte', left: '72', top: '46',
  },
  {
    acronym: 'NAO', url: 'nao', label: 'Oscilação do Atlântico Norte', left: '74', top: '27',
  },
  {
    acronym: 'Trópicos', url: 'tropicos', label: 'Perturbações Tropicais', left: '45', top: '62',
  },
  {
    acronym: 'TSA', url: 'tsa', label: 'Índice do Atlântico Tropical Sul', left: '77', top: '55',
  },
  {
    acronym: 'IOD', url: 'iod', label: 'Dipolo do Oceano Índico', left: '10', top: '50',
  },
];
