import filterUndefined from 'utils/filterUndefined';
import { store } from 'redux/store';
import jwt_decode from 'jwt-decode';
import { JWTContent } from 'redux/reducers/authReducer';
import {
  RawNumericForecastImagesResponse,
  NumericForecastImage,
  PopularComparisonsResponse,
  rawToNumericForecastImage,
  Comparison,
  ControlPanelTypes,
  ControlPanelOption,
  rawToControlPanelOptions,
  AvailableComparisonDates,
  RawAvailableComparisonDatesResponse,
  rawToAvailableComparisonDates,
  RawComparisonImagesResponse,
  RawStatus,
  RawComparisonPeriodsResponse,
} from 'helper/products/meteorology';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import baseApi, { GenericResponse, rawBaseQuery } from '../base';

const TIME_FOR_CACHE_KEEPING = 10 * 60; // FIVE MINUTES

export interface JsonPreferences {
  [preference: string]: any,
  modelos: string[],
  data_prev: string,
  area_plotagem: string,
  periodo_interesse: string,
  anom: boolean,
  variavel_meteorologica: string,
}

export interface PreferenceList {
  id: number,
  name: string,
  user_id: number,
  json: JsonPreferences,
}

export interface NumericForecastImagesParams {
  forecastUnixDate: number
  region: string
  models: string[]
  period: string
  variable: string
  anomaly: boolean
}

export interface SavePntPreferencesParams {
  id: number,
  name: string,
  plotArea: string,
  models: string[],
  anomaly: boolean,
  forecastDate: string, // format: dd/mm/yyyy
  meteorologicVariable: string,
  interestPeriod: string,
}

export interface ComparatorPreferenceModel {
  modelName: string,
  forecastDate: string,
  runtime: number,
  member: string,
  atmLevel: string,
  meteorologicVariable: string,
  rmv: number,
}

export interface SaveComparatorPreferencesParams {
  id: number,
  name: string,
  baseModel: ComparatorPreferenceModel,
  confrontingModel: ComparatorPreferenceModel,
  period: string,
}

export interface SaveObservedDataPreferencesParams {
  id: number,
  name: string,
  plotArea: string,
  meteorologicVariable: string,
  selectionPeriod: string,
  showSeasons: boolean,
}

export interface DeletePreferencesParams {
  id: number,
}

export interface MaxHorizonParams {
  provisionalDate: number,
  region: string,
  modelList: string[],
  runtime: number,
  member: string,
  AtmLevel: string,
  variable: string,
  period: string,
}

export interface MaxHorizonResponse {
  status: number,
  data: {
    method: string,
    response: string,
    status_code: number,
    data_inicial: number,
    data_final: number,
    num_dias: number,
  },
}

export interface ComparisonParams {
  model: string,
  forecastDate: string, // eg 'latest', '2nd_latest'
  runtime: number,
  member: string,
  rmv: number,
}

export interface ComparisonImagesParams {
  base: ComparisonParams,
  confronting: ComparisonParams,
  period: string, // eg 'd+1'
}

export interface AvailableComparisonDatesParams {
  model: string,
}

export interface ComparisonPeriodsParams {
  base: ComparisonParams,
  confronting: ComparisonParams,
}

export interface ObservedDataParams {
  region: string,
  variable: string,
  preset?: string,
  initialTimestamp?: number,
  finalTimestamp?: number,
  dataPerStation: boolean,
  anomaly: boolean,
}

export interface ObservedDataImage {
  obs: string,
  clim: string,
  anom: string,
}

export type StatusResponse = GenericResponse<RawStatus[]>;

export const meteorologyApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    controlPanelOptions: build.query<ControlPanelOption[], ControlPanelTypes>({
      query: (type) => ({
        url: 'produtos/meteorologia/painel-options',
        method: 'POST',
        body: {
          type,
        },
      }),
      transformResponse: rawToControlPanelOptions,
    }),
    listPntPreferences: build.query<GenericResponse<PreferenceList[]>, void>({
      query: () => {
        const userId = jwt_decode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/meteorologia/preferencias-pnt-list',
          method: 'POST',
          body: { user_id: userId },
        };
      },
      providesTags: ['PntPreferences'],
    }),
    listComparatorPreferences: build.query<GenericResponse<Comparison[]>, void>({
      query: () => {
        const userId = jwt_decode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/meteorologia/preferencias-comparador-list',
          method: 'POST',
          body: { user_id: userId },
        };
      },
      providesTags: ['ComparatorPreferences'],
    }),
    listDataPreferences: build.query<GenericResponse<PreferenceList[]>, void>({
      query: () => {
        const userId = jwt_decode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/meteorologia/preferencias-dados-list',
          method: 'POST',
          body: { user_id: userId },
        };
      },
      providesTags: ['ObservedDataPreferences'],
    }),
    getNumericForecastImages: build.query<NumericForecastImage[], NumericForecastImagesParams>({
      queryFn: async (params, api, extraOptions) => {
        const productKey = store.getState().productKeys.meteorologia;

        const response = await rawBaseQuery(
          {
            url: `produtos/pnt/image-retriever/?product_key=${productKey}`,
            method: 'POST',
            body: {
              data_previsao: params.forecastUnixDate,
              regiao: params.region,
              lista_modelos: params.models,
              runtime: 0,
              membro: 0,
              nivel_atm: 'single_level',
              var: params.variable,
              periodo: params.period,
              anom: params.anomaly,
            },
          },
          api,
          extraOptions,
        ) as QueryReturnValue<RawNumericForecastImagesResponse, unknown, unknown>;

        const transformedResponse = {
          ...response,
          data: rawToNumericForecastImage(response.data),
        } as QueryReturnValue<NumericForecastImage[], unknown, unknown>;

        return transformedResponse;
      },
      keepUnusedDataFor: TIME_FOR_CACHE_KEEPING,
    }),
    savePntPreferences: build.mutation<GenericResponse<null>, SavePntPreferencesParams>({
      query: ({
        id, name, plotArea, models, forecastDate, meteorologicVariable, interestPeriod, anomaly,
      }) => {
        const userId = jwt_decode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/meteorologia/preferencias-pnt-save',
          method: 'POST',
          body: {
            id,
            user_id: userId,
            name,
            preferencias_json: {
              area_plotagem: plotArea,
              modelos: models,
              data_prev: forecastDate,
              variavel_meteorologica: meteorologicVariable,
              periodo_interesse: interestPeriod,
              anom: anomaly,
            },
          },
        };
      },
      invalidatesTags: ['PntPreferences'],
    }),
    // eslint-disable-next-line max-len
    saveComparatorPreferences: build.mutation<GenericResponse<null>, SaveComparatorPreferencesParams>({
      query: ({
        id, name, baseModel, confrontingModel, period,
      }) => {
        const userId = jwt_decode<JWTContent>(store.getState().auth.jwt).user_id;
        const modelParams = (model: ComparatorPreferenceModel) => ({
          modelo: model.modelName,
          data_previsao: model.forecastDate,
          runtime: model.runtime,
          membro: model.member,
          nivel_atm: model.atmLevel,
          var: model.meteorologicVariable,
          rmv: model.rmv,
        });

        const bodyJson = {
          id,
          user_id: userId,
          name,
          preferencias_json: {
            base: modelParams(baseModel),
            confrontante: modelParams(confrontingModel),
            periodo: period,
          },
        };

        return {
          url: 'produtos/meteorologia/preferencias-comparador-save',
          method: 'POST',
          body: bodyJson,
        };
      },
      invalidatesTags: ['ComparatorPreferences'],
    }),
    // eslint-disable-next-line max-len
    saveObservedDataPreferences: build.mutation<GenericResponse<null>, SaveObservedDataPreferencesParams>({
      query: ({
        id, name, plotArea, meteorologicVariable, selectionPeriod, showSeasons,
      }) => {
        const userId = jwt_decode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/meteorologia/preferencias-dados-observados-save',
          method: 'POST',
          body: {
            id,
            user_id: userId,
            name,
            preferencias_json: {
              area_plotagem: plotArea,
              variavel_meteorologica: meteorologicVariable,
              periodo: selectionPeriod,
              estacoes: showSeasons,
            },
          },
        };
      },
      invalidatesTags: ['ObservedDataPreferences'],
    }),
    deletePntPreferences: build.mutation<GenericResponse<null>, DeletePreferencesParams>({
      query: ({ id }) => {
        const userId = jwt_decode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/meteorologia/preferencias-pnt-delete',
          method: 'DELETE',
          body: {
            id,
            user_id: userId,
          },
        };
      },
      invalidatesTags: ['PntPreferences'],
    }),
    deleteComparatorPreferences: build.mutation<GenericResponse<null>, DeletePreferencesParams>({
      query: ({ id }) => {
        const userId = jwt_decode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/meteorologia/preferencias-comparador-delete',
          method: 'DELETE',
          body: {
            id,
            user_id: userId,
          },
        };
      },
      invalidatesTags: ['ComparatorPreferences'],
    }),
    deleteObservedDataPreferences: build.mutation<GenericResponse<null>, DeletePreferencesParams>({
      query: ({ id }) => {
        const userId = jwt_decode<JWTContent>(store.getState().auth.jwt).user_id;

        return {
          url: 'produtos/meteorologia/preferencias-dados-observados-delete',
          method: 'DELETE',
          body: {
            id,
            user_id: userId,
          },
        };
      },
      invalidatesTags: ['ObservedDataPreferences'],
    }),
    getMaxHorizonDate: build.query<MaxHorizonResponse, MaxHorizonParams>({
      queryFn: async (params, api, extraOptions) => {
        const {
          provisionalDate, region, modelList, runtime,
          member, AtmLevel, variable, period,
        } = params;
        const productKey = store.getState().productKeys.meteorologia;

        return rawBaseQuery({
          url: `produtos/pnt/horizonte-max/?product_key=${productKey}`,
          method: 'POST',
          body: {
            data_previsao: provisionalDate,
            regiao: region,
            lista_modelos: modelList,
            runtime,
            membro: member,
            nivel_atm: AtmLevel,
            var: variable,
            periodo: period,
          },
        }, api, extraOptions) as QueryReturnValue<MaxHorizonResponse>;
      },
    }),
    popularComparisons: build.query<PopularComparisonsResponse, void>({
      query: () => 'produtos/meteorologia/preferencias-comparador-list-populares',
    }),
    getComparisonImages: build.query<RawComparisonImagesResponse, ComparisonImagesParams>({
      query: ({ base, confronting, period }) => {
        const productKey = store.getState().productKeys.meteorologia;

        return {
          url: `produtos/comparador/execute-comparison/?product_key=${productKey}`,
          method: 'POST',
          body: {
            base: {
              modelo: base.model,
              data_previsao: base.forecastDate,
              runtime: base.runtime,
              membro: base.member,
              nivel_atm: 'single_level',
              var: 'prec',
              rmv: base.rmv,
            },
            confrontante: {
              modelo: confronting.model,
              data_previsao: confronting.forecastDate,
              runtime: confronting.runtime,
              membro: confronting.member,
              nivel_atm: 'single_level',
              var: 'prec',
              rmv: confronting.rmv,
            },
            periodo: period,
          },
        };
      },
      keepUnusedDataFor: TIME_FOR_CACHE_KEEPING,
    }),
    // eslint-disable-next-line max-len
    getAvailableComparisonDates: build.query<AvailableComparisonDates, AvailableComparisonDatesParams>({
      queryFn: async (params, api, extraOptions) => {
        const productKey = store.getState().productKeys.meteorologia;

        const response = await rawBaseQuery(
          {
            url: `produtos/comparador/check-availables-dates/?product_key=${productKey}`,
            method: 'POST',
            body: { modelo_value: params.model },
          },
          api,
          extraOptions,
        ) as QueryReturnValue<RawAvailableComparisonDatesResponse, unknown, unknown>;

        const transformedResponse = {
          ...response,
          data: rawToAvailableComparisonDates(response.data),
        } as QueryReturnValue<AvailableComparisonDates, unknown, unknown>;

        return transformedResponse;
      },
    }),
    getComparisonImagesPeriods: build.query<RawComparisonPeriodsResponse, ComparisonPeriodsParams>({
      query: ({ base, confronting }) => {
        const productKey = store.getState().productKeys.meteorologia;

        return {
          url: `produtos/comparador/possible-intervals/?product_key=${productKey}`,
          method: 'POST',
          body: {
            base: {
              modelo: base.model,
              data_previsao: base.forecastDate,
              runtime: base.runtime,
              membro: base.member,
              nivel_atm: 'single_level',
              var: 'prec',
              rmv: base.rmv,
            },
            confrontante: {
              modelo: confronting.model,
              data_previsao: confronting.forecastDate,
              runtime: confronting.runtime,
              membro: confronting.member,
              nivel_atm: 'single_level',
              var: 'prec',
              rmv: confronting.rmv,
            },
          },
        };
      },
    }),
    getObservedDataImages: build.query<GenericResponse<ObservedDataImage>, ObservedDataParams>({
      query: ({
        region, variable, preset, initialTimestamp, finalTimestamp, dataPerStation, anomaly,
      }) => {
        const productKey = store.getState().productKeys.meteorologia;

        return {
          url: `produtos/dados-observados/execute/?product_key=${productKey}`,
          method: 'POST',
          body: filterUndefined({
            var: variable,
            regiao: region,
            predefinido: preset,
            datahora_final: finalTimestamp,
            datahora_inicial: initialTimestamp,
            dados_por_estacao: dataPerStation,
            anom: anomaly,
          }),
        };
      },
      keepUnusedDataFor: TIME_FOR_CACHE_KEEPING,
    }),
    getAvailableFluxPersonalizedDates:
      build.query<GenericResponse<number[]>, AvailableComparisonDatesParams>({
        query: ({ model }) => {
          const productKey = store.getState().productKeys['prevs-personalizado'];

          return {
            url: `produtos/previvaz-personalizado/check-availables-dates/?product_key=${productKey}`,
            method: 'POST',
            body: { modelo_value: model },
          };
        },
      }),
    getStatus: build.query<StatusResponse, void>({
      query: () => 'produtos/meteorologia/get-last',
    }),
  }),
});

export const {
  useListPntPreferencesQuery,
  useListComparatorPreferencesQuery,
  useListDataPreferencesQuery,
  useGetNumericForecastImagesQuery,
  useLazyGetNumericForecastImagesQuery,
  useSavePntPreferencesMutation,
  useSaveComparatorPreferencesMutation,
  useSaveObservedDataPreferencesMutation,
  useDeletePntPreferencesMutation,
  useDeleteComparatorPreferencesMutation,
  useDeleteObservedDataPreferencesMutation,
  useGetMaxHorizonDateQuery,
  usePopularComparisonsQuery,
  useControlPanelOptionsQuery,
  useGetComparisonImagesQuery,
  useLazyGetComparisonImagesQuery,
  useGetAvailableComparisonDatesQuery,
  useGetComparisonImagesPeriodsQuery,
  useLazyGetComparisonImagesPeriodsQuery,
  useGetObservedDataImagesQuery,
  useLazyGetObservedDataImagesQuery,
  useLazyGetAvailableFluxPersonalizedDatesQuery,
  useGetStatusQuery,
  useLazyGetStatusQuery,
} = meteorologyApi;
