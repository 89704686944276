import { useSelector } from 'react-redux';
import {
  selectMFClassicComparatorVariable,
  selectMFClassicComparatorBaseModel,
  selectMFClassicComparatorConfrontingModel,
} from 'redux/reducers/meteorologyFieldsClassicComparatorControlPanelReducer';
import { Images } from 'types/Meteorology/Fields/ClassicComparator';
import downloadFile from 'helper/downloadFile';

export default function useImagesDownload(images: Images | undefined) {
  const variable = useSelector(selectMFClassicComparatorVariable);
  const baseModel = useSelector(selectMFClassicComparatorBaseModel);
  const confrontingModel = useSelector(selectMFClassicComparatorConfrontingModel);
  const imageName = {
    base: 'base',
    confronting: 'confrontante',
    difference: 'diferença',
  };

  const downloadDisabled = images === undefined;

  const onDownloadClick = () => {
    if (!downloadDisabled) {
      Object.keys(images).forEach((key) => {
        const filename = `comparador_classico_${variable}_${baseModel}_${confrontingModel}_${imageName[key]}.png`;
        downloadFile(images[key], filename);
      });
    }
  };

  return {
    downloadDisabled,
    onDownloadClick,
  };
}
