import React, { useMemo } from 'react';
import { ListedStudy } from 'helper/studies';
import './index.scss';

export default function StatusTag({ study }: { study: ListedStudy }) {
  const colorClass = useMemo(() => {
    if (study.id_status === 'Em Fila') {
      return 'study-status-gray';
    }
    if (study.id_status === 'Com Erro') {
      return 'study-status-red';
    }
    if (study.id_status === 'Estudo Pronto') {
      return 'study-status-green';
    }
    return '';
  }, [study.id_status]);

  return (
    <div className="d-flex flex-row mb-2">
      <div className={`align-self-center study-status-tag ${colorClass}`}>{study.id_status}</div>
      {study.ck_internal_error && (
        <div className="align-self-center study-status-info">
          Verifique seu e-mail de processamento interrompido para mais informações
        </div>
      )}
    </div>
  );
}
