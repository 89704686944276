import { useCallback } from 'react';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  selectMFClassicComparatorBaseModel,
  setBaseModel as setBaseModelRedux,
  selectMFClassicComparatorBaseForecastDateDiff,
  setBaseForecastDateDiff as setBaseForecastDateDiffRedux,
  selectMFClassicComparatorBaseRuntime,
  setBaseRuntime as setBaseRuntimeRedux,
  selectMFClassicComparatorBaseMember,
  setBaseMember as setBaseMemberRedux,
  selectMFClassicComparatorBaseRMV,
  setBaseRMV as setBaseRMVRedux,
} from 'redux/reducers/meteorologyFieldsClassicComparatorControlPanelReducer';

export default function useClassicComparatorBaseModel() {
  const baseModel = useSelector(selectMFClassicComparatorBaseModel);
  const baseForecastDateDiff = useSelector(selectMFClassicComparatorBaseForecastDateDiff);
  const baseRuntime = useSelector(selectMFClassicComparatorBaseRuntime);
  const baseMember = useSelector(selectMFClassicComparatorBaseMember);
  const baseRMV = useSelector(selectMFClassicComparatorBaseRMV);

  const setBaseModel = useCallback((value: string) => {
    store.dispatch(setBaseModelRedux(value));
  }, []);
  const setBaseForecastDateDiff = useCallback((value: string | undefined) => {
    store.dispatch(setBaseForecastDateDiffRedux(value));
  }, []);
  const setBaseRuntime = useCallback((value: number | undefined) => {
    store.dispatch(setBaseRuntimeRedux(value));
  }, []);
  const setBaseMember = useCallback((value: string | undefined) => {
    store.dispatch(setBaseMemberRedux(value));
  }, []);
  const setBaseRMV = useCallback((value: boolean) => {
    store.dispatch(setBaseRMVRedux(value));
  }, []);

  return {
    baseModel,
    setBaseModel,
    setBaseForecastDateDiff,
    baseForecastDateDiff,
    baseRuntime,
    setBaseRuntime,
    baseMember,
    setBaseMember,
    setBaseRMV,
    baseRMV,
  };
}
