import { useMemo } from 'react';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  setModelPeriods as setModelPeriodsRedux,
  selectMWComparatorControlPanelModelPeriods,
} from 'redux/reducers/meteorologyWeatherComparatorControlPanelReducer';
import buildAccordionClickFunction from 'helper/buildAccordionClickFunction';

export default function usePeriod({ modelIndex, accordionId }: {
  modelIndex: number;
  accordionId: string;
}) {
  const modelPeriods = useSelector(selectMWComparatorControlPanelModelPeriods);
  const closeAccordion = useMemo(() => buildAccordionClickFunction(accordionId), [accordionId]);

  const getModelPeriod = () => {
    const startDate = modelPeriods[modelIndex].startDate !== ''
      ? new Date(modelPeriods[modelIndex].startDate)
      : undefined;
    const endDate = modelPeriods[modelIndex].endDate !== ''
      ? new Date(modelPeriods[modelIndex].endDate)
      : undefined;
    return { startDate, endDate };
  };

  const setModelPeriod = (period: { startDate: string; endDate: string }) => {
    const newModelPeriods = [...modelPeriods];
    newModelPeriods[modelIndex] = period;
    store.dispatch(setModelPeriodsRedux(newModelPeriods));
  };

  const changePeriod = (dates) => {
    const [startDate, endDate] = dates;
    const stringStartDate = startDate ? startDate.toString() : '';
    const stringEndDate = endDate ? endDate.toString() : '';
    setModelPeriod({ startDate: stringStartDate, endDate: stringEndDate });
    if (startDate && endDate) {
      closeAccordion();
    }
  };

  return {
    modelPeriods,
    getModelPeriod,
    setModelPeriod,
    changePeriod,
  };
}
