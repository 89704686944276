import { useCallback, useEffect, useMemo } from 'react';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  selectMWSeasonalForecastControlPanelArea,
  selectMWSeasonalForecastControlPanelModel,
  selectMWSeasonalForecastControlPanelVariable,
  setArea as setAreaRedux,
} from 'redux/reducers/meteorologyWeatherSeasonalForecastControlPanelReducer';
import useSeasonalForecastOptions from './useSeasonalForecastOptions';

export default function useArea() {
  const { options } = useSeasonalForecastOptions();
  const variable = useSelector(selectMWSeasonalForecastControlPanelVariable);
  const model = useSelector(selectMWSeasonalForecastControlPanelModel);
  const area = useSelector(selectMWSeasonalForecastControlPanelArea);

  const models = useMemo(() => (
    options.find((v) => (v.id === variable))?.models
  ), [options, variable]);
  const areas = useMemo(() => (
    models?.find((m) => m.id === model)?.areas || []
  ), [models, model]);

  const setArea = useCallback((value: string) => {
    store.dispatch(setAreaRedux(value));
  }, []);

  useEffect(() => {
    const areaIds = areas.map((a) => a.id);
    const areaIsMissing = !areaIds.includes(area);
    if (areaIsMissing) store.dispatch(setAreaRedux(undefined));
  }, [area, areas]);

  return {
    areas,
    area,
    setArea,
  };
}
