import React from 'react';
import { Accordion } from 'react-bootstrap';
import { Variable } from 'helper/products/meteorology';
import VariableButton from '../VariableButton';

export default function VariablesAccordionItem({
  variables,
  eventKey,
}: {
  variables: Variable[],
  eventKey: string,
}) {
  return (
    <Accordion.Item eventKey={eventKey} className="control-panel-item">
      <Accordion.Header>Variável</Accordion.Header>
      <Accordion.Body className="d-grid">
        {variables.map((v) => (
          <VariableButton key={v.id} text={v.label} value={v.id} />
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
}
