import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CustomAlert } from 'components/AlertsCollection';
import { store } from 'redux/store';
import { ResponseError } from 'services/api/base';
import { selectMFClassicComparatorRequestTriggered, setRequestTriggered } from 'redux/reducers/meteorologyFieldsClassicComparatorControlPanelReducer';
import { useLazyGetImagesQuery } from 'services/api/Meteorology/Fields/ClassicComparator';
import useGetImagesParams from './useGetImagesParams';

export default function useImages() {
  const requestTriggered = useSelector(selectMFClassicComparatorRequestTriggered);
  const params = useGetImagesParams();
  const [trigger, { data: images, error, isFetching }] = useLazyGetImagesQuery();

  const alert = useMemo(() => (error ? {
    title: 'Erro',
    message: (error as ResponseError).data,
    variant: 'warning',
  } as CustomAlert : undefined), [error]);

  useEffect(() => {
    if (requestTriggered && params) {
      trigger(params);
      store.dispatch(setRequestTriggered(false));
    }
  }, [params, requestTriggered, trigger]);

  return {
    images,
    alert,
    isFetching,
  };
}
