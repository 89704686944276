import { useCallback } from 'react';
import { store } from 'redux/store';
import {
  reset,
  setArea,
  setDate,
  setLayers,
  setModel,
  setPeriod,
  setPeriodOffset,
  setVariable,
} from 'redux/reducers/meteorologyWeatherSeasonalForecastControlPanelReducer';
import { SeasonalForecastPreference } from 'types/Meteorology/Weather/SeasonalForecast/listPreferences';
import useSeasonalForecastOptions from './useSeasonalForecastOptions';

export default function useSelectPreference() {
  const { options } = useSeasonalForecastOptions();
  const preferencePeriodData = useCallback((preference: SeasonalForecastPreference) => {
    const modelOptions = options
      ?.find((v) => v?.id === preference.json.variable)?.models
      ?.find((m) => m?.id === preference.json.model);
    const isMonthly = preference.json.formattedPeriod.charAt(0) === 'M';
    const periods = isMonthly ? modelOptions?.months : modelOptions?.trimesters;

    return {
      period: isMonthly ? 'month' : 'trimester',
      offset: periods?.indexOf(preference.json.formattedPeriod) || 0,
    };
  }, [options]);

  const selectPreferenceTrigger = (preference: SeasonalForecastPreference) => () => {
    const { period, offset } = preferencePeriodData(preference);
    store.dispatch(reset());
    store.dispatch(setVariable(preference.json.variable));
    store.dispatch(setModel(preference.json.model));
    store.dispatch(setArea(preference.json.area));
    store.dispatch(setDate(preference.json.date));
    store.dispatch(setPeriod(period));
    store.dispatch(setPeriodOffset(offset));
    store.dispatch(setLayers(preference.json.layers));
  };

  return {
    selectPreferenceTrigger,
  };
}
