import useModel from 'hooks/Meteorology/Weather/SeasonalForecast/useModel';
import { SeasonalForecastModel } from 'types/Meteorology/Weather/SeasonalForecast/getSeasonalForecastOptions';

export default function useCenter() {
  const { model, models } = useModel();
  const selectedModel = models.find((m: SeasonalForecastModel) => m.id === model);

  return {
    center: selectedModel?.center,
  };
}
