import useSeasonalForecastOptions
  from 'hooks/Meteorology/Weather/SeasonalForecast/useSeasonalForecastOptions';
import { useSelector } from 'react-redux';
import { store } from 'redux/store';
import {
  selectMWSeasonalForecastControlPanelPeriod,
  selectMWSeasonalForecastControlPanelPeriodOffset,
  setPeriod as setPeriodRedux,
  setPeriodOffset as setPeriodOffsetRedux,
  selectMWSeasonalForecastControlPanelVariable,
  selectMWSeasonalForecastControlPanelModel,
} from 'redux/reducers/meteorologyWeatherSeasonalForecastControlPanelReducer';

export default function useSelectionPeriods() {
  const { options } = useSeasonalForecastOptions();
  const period = useSelector(selectMWSeasonalForecastControlPanelPeriod);
  const periodOffset = useSelector(selectMWSeasonalForecastControlPanelPeriodOffset);
  const variable = useSelector(selectMWSeasonalForecastControlPanelVariable);
  const model = useSelector(selectMWSeasonalForecastControlPanelModel);

  const modelOptions = options?.find((v) => v?.id === variable)?.models
    ?.find((m) => m?.id === model);

  const monthPeriods = [...modelOptions?.months || []];
  const trimesterPeriods = [...modelOptions?.trimesters || []];

  const setPeriod = (value: string) => {
    store.dispatch(setPeriodRedux(value));
    store.dispatch(setPeriodOffsetRedux(0));
  };

  const setPeriodOffset = (value: number) => {
    store.dispatch(setPeriodOffsetRedux(value));
  };

  const setPeriodAsMonth = () => {
    setPeriod('month');
  };

  const setPeriodAsTrimester = () => {
    setPeriod('trimester');
  };

  const isMonthly = period ? period[0] === 'm' : false;
  const isTrimesterly = period ? period[0] === 't' : false;
  const isSelected = isMonthly || isTrimesterly;

  const formattedOption = isMonthly ? monthPeriods[periodOffset] : trimesterPeriods[periodOffset];
  const formattedPeriod = isSelected ? formattedOption : undefined;
  const isDisabled = !(monthPeriods.length || trimesterPeriods.length) || !isSelected;

  return {
    period,
    periodOffset,
    monthPeriods,
    trimesterPeriods,
    setPeriod,
    setPeriodOffset,
    setPeriodAsMonth,
    setPeriodAsTrimester,
    isMonthly,
    isTrimesterly,
    isDisabled,
    formattedPeriod,
  };
}
