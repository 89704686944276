import { DisplayData, DisplayImage } from 'helper/products/meteorology';
import { Images } from 'types/Meteorology/Fields/ClassicComparator';

const COLUMN_MAP = {
  confronting: 'Modelo Confrontante',
  base: 'Modelo Base',
  difference: 'Diferença',
};

export default function buildImageDisplayData(images: Images | undefined): DisplayData {
  const columnKeys = Object.keys(COLUMN_MAP);
  const columnLabels = Object.values(COLUMN_MAP);

  const imageSet: DisplayImage[] = [];

  columnKeys.forEach((col) => {
    const base64 = images?.[col] || '';

    imageSet.push({
      key: col,
      title: '',
      base64,
    });
  });

  return {
    imageSets: [imageSet],
    columnLabels,
    rowLabels: [],
  };
}
