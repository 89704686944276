import { useCallback } from 'react';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  selectMFClassicComparatorConfrontingModel,
  setConfrontingModel as setConfrontingModelRedux,
  selectMFClassicComparatorConfrontingForecastDateDiff,
  setConfrontingForecastDateDiff as setConfrontingForecastDateDiffRedux,
  selectMFClassicComparatorConfrontingRuntime,
  setConfrontingRuntime as setConfrontingRuntimeRedux,
  selectMFClassicComparatorConfrontingMember,
  setConfrontingMember as setConfrontingMemberRedux,
  selectMFClassicComparatorConfrontingRMV,
  setConfrontingRMV as setConfrontingRMVRedux,
} from 'redux/reducers/meteorologyFieldsClassicComparatorControlPanelReducer';

export default function useClassicComparatorConfrontingModel() {
  const confrontingModel = useSelector(selectMFClassicComparatorConfrontingModel);
  const confrontingForecastDateDiff = useSelector(
    selectMFClassicComparatorConfrontingForecastDateDiff,
  );
  const confrontingRuntime = useSelector(selectMFClassicComparatorConfrontingRuntime);
  const confrontingMember = useSelector(selectMFClassicComparatorConfrontingMember);
  const confrontingRMV = useSelector(selectMFClassicComparatorConfrontingRMV);

  const setConfrontingModel = useCallback((value: string) => {
    store.dispatch(setConfrontingModelRedux(value));
  }, []);
  const setConfrontingForecastDateDiff = useCallback((value: string | undefined) => {
    store.dispatch(setConfrontingForecastDateDiffRedux(value));
  }, []);
  const setConfrontingRuntime = useCallback((value: number | undefined) => {
    store.dispatch(setConfrontingRuntimeRedux(value));
  }, []);
  const setConfrontingMember = useCallback((value: string | undefined) => {
    store.dispatch(setConfrontingMemberRedux(value));
  }, []);
  const setConfrontingRMV = useCallback((value: boolean) => {
    store.dispatch(setConfrontingRMVRedux(value));
  }, []);

  return {
    confrontingModel,
    setConfrontingModel,
    setConfrontingForecastDateDiff,
    confrontingForecastDateDiff,
    confrontingRuntime,
    setConfrontingRuntime,
    confrontingMember,
    setConfrontingMember,
    setConfrontingRMV,
    confrontingRMV,
  };
}
