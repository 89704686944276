import React from 'react';

import Overview from 'views/Meteorology/Weather/Overview';
import Area from 'views/Meteorology/Weather/Area';
import Oscillations from 'views/Meteorology/Weather/Oscillations';
import mapAnnotations from 'helper/Meteorology/Weather/Overview/AnomalyPanel/mapAnnotations';

const oscillationsRouter = {
  path: 'oscillations',
  element: <Oscillations />,
  children: [
    { path: '', element: <Overview /> },
    ...mapAnnotations.map((entry) => ({
      path: entry.url, element: <Area areaAcronym={entry.url} areaLabel={entry.label} />,
    })),
  ],
};

export default oscillationsRouter;
