import React from 'react';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';

export default function SavePreferenceForm({
  valid,
  isLoading,
  onNameChange,
  handleSubmit,
}: {
  valid: boolean;
  isLoading: boolean;
  onNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
}) {
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="justify-content-center">
          <Col className="text-center">
            Antes de salvar, dê um
            {' '}
            <b>nome</b>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="text-center">
            <p>para sua nova preferência.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="preferenceName">
              <Form.Control required type="text" onInput={onNameChange} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="text-center">
            <Button
              type="submit"
              className={`text-nowrap mt-3 ${valid ? 'primary-button' : 'secondary-button'}`}
              disabled={!valid}
            >
              {isLoading && <Spinner size="sm" />}
              Salvar nova preferência
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
