import { useCallback } from 'react';
import { store } from 'redux/store';
import { reset } from 'redux/reducers/meteorologyFieldsClassicComparatorControlPanelReducer';

export default function useClassicComparatorUtility() {
  const resetPreferences = useCallback(() => {
    store.dispatch(reset());
  }, []);

  return {
    resetPreferences,
  };
}
