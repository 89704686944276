import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectMFClassicComparatorVariable,
  selectMFClassicComparatorBaseModel,
  selectMFClassicComparatorConfrontingModel,
  selectMFClassicComparatorBaseRMV,
  selectMFClassicComparatorConfrontingMember,
  selectMFClassicComparatorConfrontingRMV,
  selectMFClassicComparatorBaseForecastDateDiff,
  selectMFClassicComparatorConfrontingForecastDateDiff,
  selectMFClassicComparatorBaseMember,
  selectMFClassicComparatorConfrontingRuntime,
  selectMFClassicComparatorBaseRuntime,
} from 'redux/reducers/meteorologyFieldsClassicComparatorControlPanelReducer';
import { GetPossibleIntervalsParams } from 'types/Meteorology/Fields/ClassicComparator';

const hasUndefinedValue = (params: GetPossibleIntervalsParams) => (
  Object.values(params.base).some((value) => value === undefined)
    || Object.values(params.confronting).some((value) => value === undefined)
);

export default function useGetPossibleIntervalsParams() {
  const variable = useSelector(selectMFClassicComparatorVariable);

  const baseModel = useSelector(selectMFClassicComparatorBaseModel);
  const baseRMV = useSelector(selectMFClassicComparatorBaseRMV);
  const baseDateDiff = useSelector(selectMFClassicComparatorBaseForecastDateDiff);
  const baseRuntime = useSelector(selectMFClassicComparatorBaseRuntime);
  const baseMember = useSelector(selectMFClassicComparatorBaseMember);

  const confrontingMember = useSelector(selectMFClassicComparatorConfrontingMember);
  const confrontingRuntime = useSelector(selectMFClassicComparatorConfrontingRuntime);
  const confrontingDateDiff = useSelector(selectMFClassicComparatorConfrontingForecastDateDiff);
  const confrontingRMV = useSelector(selectMFClassicComparatorConfrontingRMV);
  const confrontingModel = useSelector(selectMFClassicComparatorConfrontingModel);

  const params: GetPossibleIntervalsParams = useMemo(() => ({
    base: {
      variable,
      model: baseModel,
      forecastDateDiff: baseDateDiff,
      rmv: Number(baseRMV),
      runtime: baseRuntime,
      member: baseMember,
    },
    confronting: {
      variable,
      model: confrontingModel,
      forecastDateDiff: confrontingDateDiff,
      rmv: Number(confrontingRMV),
      runtime: confrontingRuntime,
      member: confrontingMember,
    },
  }), [
    variable,
    baseModel,
    confrontingModel,
    baseRMV,
    confrontingRMV,
    baseDateDiff,
    confrontingDateDiff,
    baseRuntime,
    confrontingRuntime,
    baseMember,
    confrontingMember,
  ]);

  return hasUndefinedValue(params) ? undefined : params;
}
