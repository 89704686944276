import { useRef, useEffect } from 'react';
import useImageRowSize from './useImageRowSize';

export default function useHandleImageRowSizeChange(columnLabels: string[]) {
  const { rowSize, setRowSize } = useImageRowSize();
  const initialRowSize = useRef(rowSize);

  useEffect(() => () => { setRowSize(initialRowSize.current); }, [setRowSize]);

  useEffect(() => {
    if (columnLabels.length) setRowSize(columnLabels.length);
  }, [setRowSize, columnLabels.length]);
}
