import { useCallback } from 'react';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import {
  reset,
  selectMFClassicComparatorVariable,
  setVariable as setVariableRedux,
} from 'redux/reducers/meteorologyFieldsClassicComparatorControlPanelReducer';

export default function useClassicComparatorVariable() {
  const variable = useSelector(selectMFClassicComparatorVariable);

  const setVariable = useCallback((value: string) => {
    if (variable !== value) {
      store.dispatch(reset());
      store.dispatch(setVariableRedux(value));
    }
  }, [variable]);

  return {
    variable,
    setVariable,
  };
}
