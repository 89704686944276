import React from 'react';
import PreferencesAccordion from 'components/PreferencesAccordion';
import usePreferences from 'hooks/Meteorology/Weather/SeasonalForecast/usePreferences';
import useSelectPreference from 'hooks/Meteorology/Weather/SeasonalForecast/useSelectPreference';
import useDeletePreference from 'hooks/Meteorology/Weather/SeasonalForecast/useDeletePreference';

export default function Preferences() {
  const { preferences } = usePreferences();
  const { selectPreferenceTrigger } = useSelectPreference();
  const { preferenceDeleteTrigger, isLoading } = useDeletePreference();

  return (
    <PreferencesAccordion
      preferenceList={preferences}
      selectTrigger={selectPreferenceTrigger}
      deleteTrigger={preferenceDeleteTrigger}
      deleteLoading={isLoading}
    />
  );
}
