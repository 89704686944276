import { useSelector } from 'react-redux';
import { store } from 'redux/store';
import {
  setLayers as setLayersRedux,
  selectMWSeasonalForecastControlPanelLayers,
} from 'redux/reducers/meteorologyWeatherSeasonalForecastControlPanelReducer';

export default function useLayers() {
  const layer = useSelector(selectMWSeasonalForecastControlPanelLayers);
  const setLayers = (layers: boolean) => store.dispatch(setLayersRedux(layers));
  const toggleLayers = () => {
    setLayers(!layer);
  };

  return {
    layer,
    toggleLayers,
  };
}
