import { HomeTutorialLink } from '../../helper/products/home';
import { useGetTutorialLinksQuery } from '../../services/api/products/home';
import useHandleRequestErrors from './useHandleRequestErrors';
import { ResponseError } from '../../services/api/base';

const FIXED_LINKS: HomeTutorialLink[] = [
  {
    id: 100,
    label: 'Oscilações',
    link: 'meteorology/weather/oscillations/',
    tutorial_video: null,
  },
  {
    id: 101,
    label: 'Previsão Sazonal',
    link: 'meteorology/weather/seasonal-forecast',
    tutorial_video: null,
  },
  {
    id: 102,
    label: 'Comparador Clássico',
    link: 'meteorology/fields/classiccomparator',
    tutorial_video: null,
  },
];

export default function useTutorialData(selectedIds: number[]): HomeTutorialLink[] {
  const { data, error } = useGetTutorialLinksQuery();
  useHandleRequestErrors(error as ResponseError | undefined);

  const fullList = data?.data ? [...data.data, ...FIXED_LINKS] : [];

  return selectedIds
    .map((id) => fullList.find((tutorial) => tutorial.id === id))
    .filter((element) => element !== undefined) as HomeTutorialLink[];
}
