import React from 'react';

import useClassicComparatorConfrontingModel from 'utils/hooks/meteorology/classicComparator/useClassicComparatorConfrontingModel';
import ModelAccordion, { ModelType } from '../ModelAccordion';

export default function ConfrontingModel() {
  const {
    confrontingModel,
    setConfrontingModel,
    confrontingForecastDateDiff,
    setConfrontingForecastDateDiff,
    confrontingRuntime,
    setConfrontingRuntime,
    confrontingMember,
    setConfrontingMember,
    confrontingRMV,
    setConfrontingRMV,
  } = useClassicComparatorConfrontingModel();

  return (
    <ModelAccordion
      id="confronting-model-accordion"
      title="Modelo Confrontante"
      modelType={ModelType.CONFRONTATIONAL}
      model={confrontingModel}
      setModel={setConfrontingModel}
      dateDiff={confrontingForecastDateDiff}
      setDateDiff={setConfrontingForecastDateDiff}
      runtime={confrontingRuntime}
      setRuntime={setConfrontingRuntime}
      member={confrontingMember}
      setMember={setConfrontingMember}
      rmv={confrontingRMV}
      setRMV={setConfrontingRMV}
    />
  );
}
