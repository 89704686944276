import { useEffect, useState } from 'react';

import useGetImagesParams from 'hooks/Meteorology/Weather/SeasonalForecast/useGetImagesParams';
import { useLazyGetSeasonalForecastImagesQuery } from 'services/api/Meteorology/Weather/SeasonalForecast';

export default function useImages() {
  const params = useGetImagesParams();
  const [trigger, { data, isFetching }] = useLazyGetSeasonalForecastImagesQuery();
  const [image, setImage] = useState('');

  useEffect(() => {
    if (!params) {
      setImage('');
    } else {
      trigger(params);
    }
  }, [params, trigger]);

  useEffect(() => {
    if (data) setImage(data.image);
  }, [data]);

  return {
    image,
    loading: isFetching,
  };
}
